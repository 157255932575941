@import "../../../styles/_variables.scss";

.Sales_rep_selector{
    grid-area: Sales_rep_selector;

    max-width: 400px;
    border-radius: 15px;
    margin: 15px auto;
    font-weight: 600;
    font-size: 18px;
        border: 2px solid rgb(0, 207, 0);
        width: 80%;
    padding: 10px;
    background-color: rgba(0, 207, 0, 0.24);
    text-align: left;
    display: grid;
    place-content: center;
    .sales_RepSelectlist{
      text-align: left;
      width: 100%;
      margin: 0 auto;
      height: 150px;
      overflow-y: auto;
      overflow-x:hidden;
      .sales_RepSelect{
      margin: 15px 0 ;
        border: 1px solid $Primary;
        border-radius: 5px;
        padding:  5px 5px 5px 5px ;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        color: $Primary;
        cursor: pointer;
       
        list-style-type: none;
        &:hover{
          background-color: $Primary;
          color: white;
        }

       
      }
    }
  }

.Sales_Rep_Wrapper{
//background-color: aqua;
a{
  text-decoration: none;
  color: black;

}

  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));

  .Sales_rep{
    border-radius: 15px;
    border: .5px rgb(0, 0, 0) solid;
     padding: 15px;
    display: grid;
grid-template-columns: 1fr 50px 50px;
grid-template-rows: 1fr 1fr;
grid-template-areas:
" Name Incomplete_Orders Urgent_order"
" mail Incomplete_Orders Urgent_order"
;

.Urgent_order{
  place-content: center;
  display: grid;
  grid-area: Urgent_order;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
  background-color: #ff0000;
}
   .Incomplete_Orders{
      place-content: center;
      display: grid;
      grid-area: Incomplete_Orders;
      background-color: #0a6887;
      color: white;
      font-weight: 800;
      border-radius: 50px;
      max-width: 25px;
      max-height: 25px;
   
    }
    .mail{
      display: grid;
      grid-area: mail;
    }
    .Name{
      display: grid;
      grid-area: Name;
    }
&:hover{
  //  scale: 1.01;
background-color: #0b549425;
    
box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
border: 1px solid #0a688727;


transition: .25s ease-out;

  }
  }

}


.Regular_order{
  place-content: center;
      display: grid;
      grid-area: Incomplete_Orders;
      background-color: #0a6887;
      color: white;
      font-weight: 800;
      border-radius: 50px;
      max-width: 25px;
      max-height: 25px;
}

.Urgent_order{
  place-content: center;
  display: grid;
  grid-area: Urgent_order;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
  background-color: #ff0000;
}