.Prodcut_Catelog_Export_Pdf_wrapper {
  width: 100%;
  padding: 0;
  /* Set A4 page size */
  size: A4;
}
.Prodcut_Catelog_Export_Pdf_wrapper.hidden {
  display: none;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Watermark {
  position: fixed; /* Fixed position to ensure it stays in the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  opacity: 0.2; /* Adjust opacity to make it semi-transparent (0.0 - 1.0) */
  z-index: -1; /* Place watermark behind other content */
  pointer-events: none; /* Make sure the watermark doesn't interfere with interaction */
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf {
  border-collapse: collapse;
  margin: 0 auto;
  width: 95%;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow {
  page-break-inside: avoid;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  border: 1px solid #002cbe;
  page-break-inside: avoid;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow .Product_image {
  page-break-inside: avoid;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow .Product_info {
  page-break-inside: avoid;
  width: 100%;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow .Product_info .data_row {
  border: 1px solid #011146;
  width: 100%;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow .Product_info .data_row td {
  border: 1px solid #011146;
  white-space: pre-wrap;
  width: 78px;
  max-width: 78px;
  word-wrap: break-word;
}
.Prodcut_Catelog_Export_Pdf_wrapper .Prodcut_Catelog_Export_Pdf .Main_Productrow.Title {
  background-color: rgba(0, 44, 190, 0.1843137255);
}

@media print {
  table {
    position: relative;
    top: 20px;
  }
}/*# sourceMappingURL=Style.css.map */