.sectorwrapper {
  display: grid;
  grid-gap: 5px;
}
.sectorwrapper.dark {
  background-color: #333333;
  color: #fff;
}
.sectorwrapper.dark .sector {
  background-color: #1b1b1b;
  color: #fff;
}
.sectorwrapper.dark .sector.Names {
  border: 1px rgb(0, 223, 0) solid;
}
.sectorwrapper.dark .sector.Stock_rate {
  border: 1px rgb(204, 0, 0) solid;
}
.sectorwrapper.dark.Names .addtocart .addtocartBtn {
  color: #fff;
}
.sectorwrapper .sector {
  border-radius: 15px;
  border: 0.5px rgb(0, 0, 0) solid;
  padding: 15px;
}
.sectorwrapper .sector:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  transition: 0.25s ease-out;
}
.sectorwrapper .sector.Names {
  border: 0.5px green solid;
}
.sectorwrapper .sector.Stock_rate {
  border: 0.5px rgb(128, 0, 0) solid;
}

@media screen and (max-width: 768px) {
  .sectorwrapper {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
}
@media screen and (max-width: 290px) {
  .sectorwrapper {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
}
.brand_Name {
  margin-bottom: 10px;
}

.productbrandselectlist {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.productbrandselect {
  cursor: pointer;
  margin-bottom: 5px;
}

/* Modal styles */
.modal_brand {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_brand-content {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 80vw;
  height: 50vh;
  max-height: 100%;
  overflow: auto;
  top: 0;
}
.modal_brand-content .Product_history_data {
  width: 100%;
  margin: 0 auto;
}
.modal_brand-content .Product_history_data._table {
  margin: 0 auto;
}

.product_page_wrapper {
  display: grid;
  grid-gap: 10px;
}
.product_page_wrapper .product_info_page {
  margin: o 25px;
}
.product_page_wrapper .Product_stocklevels {
  margin: 0 25px;
}

@media screen and (min-width: 1000px) {
  .product_page_wrapper {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr;
    grid-template-areas: "product_info_page Product_stocklevels";
  }
  .product_page_wrapper .product_info_page {
    grid-area: product_info_page;
  }
  .product_page_wrapper .Product_stocklevels {
    display: grid;
    grid-area: Product_stocklevels;
  }
}/*# sourceMappingURL=Style.css.map */