@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.SalesOrderDashboard {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "OrderList OrderDetail";
}
.SalesOrderDashboard .OrderList {
  width: 80%;
  margin: 5px auto;
}
.SalesOrderDashboard .OrderList h3 {
  color: #0a6887;
  text-align: center;
  font-weight: 800;
}
.SalesOrderDashboard .OrderList .orderlist_Item {
  margin: 18px 0px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  display: grid;
  padding: 15px;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "Customer_Name Status Created_date" "SalesOrderId Est_total Sales_rep";
  transition: border-color 0.2s ease-in-out;
}
.SalesOrderDashboard .OrderList .orderlist_Item._selected {
  border: solid 2px #0a6887;
  transition: border-color 0.3s ease-in-out;
}
.SalesOrderDashboard .OrderList .orderlist_Item .Customer_Name {
  grid-area: Customer_Name;
  font-weight: 750;
  font-size: 13px;
}
.SalesOrderDashboard .OrderList .orderlist_Item .Status {
  grid-area: Status;
  font-weight: 800;
  font-size: 10px;
}
.SalesOrderDashboard .OrderList .orderlist_Item .Created_date {
  grid-area: Created_date;
  font-weight: 800;
  font-size: 10px;
  width: 100%;
  text-align: right;
}
.SalesOrderDashboard .OrderList .orderlist_Item .SalesOrderId {
  grid-area: SalesOrderId;
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.SalesOrderDashboard .OrderList .orderlist_Item .Est_total {
  grid-area: Est_total;
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.SalesOrderDashboard .OrderList .orderlist_Item .Sales_rep {
  grid-area: Sales_rep;
  font-weight: 600;
  font-size: 12px;
  color: gray;
  text-align: right;
}

.Order-tab-OAM {
  color: #0a6887;
  font-weight: 800;
}
.Order-tab-OAM .Mui-selected .MuiTabIndicator-root {
  background-color: #ff0000;
}
.Order-tab-OAM .MuiTabs-indicator {
  background-color: #ff0000;
}
.Order-tab-OAM .MuiTab-root.Mui-selected {
  color: #ff0000;
}

.Order-tab-Maruti {
  color: red;
  font-weight: 800;
}

.Order-tab-Harvestor {
  color: rgb(33, 143, 0);
  font-weight: 800;
}
.Order-tab-Harvestor .MuiTab-root.Mui-selected {
  color: #ff0000;
}

.Order-tab-Tafe_OE {
  color: rgb(111, 0, 255);
  font-weight: 800;
}

.Order-tab-Simpsons_OE {
  color: rgb(255, 115, 0);
  font-weight: 800;
}/*# sourceMappingURL=Style.css.map */