.print_invoice {
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas: "letterhead" "Information" "items" "footer";
  margin: 0 auto;
  padding: 10px;
}
.print_invoice.hidden {
  display: none;
}
.print_invoice .letterhead {
  grid-area: letterhead;
  width: 100%;
  margin: 0 auto;
}
.print_invoice .Information {
  margin: 0 auto;
  width: 100%;
  grid-area: Information;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 8px;
  display: grid;
  grid-template-areas: "customerinfo paymentinfo  orderinfo";
}
.print_invoice .Information .paymentinfo {
  grid-area: paymentinfo;
}
.print_invoice .Information .customerinfo {
  grid-area: customerinfo;
}
.print_invoice .Information .orderinfo {
  grid-area: orderinfo;
}
.print_invoice .items {
  grid-area: items;
}
.print_invoice .footer_invoice {
  grid-area: footer;
  width: 100%;
  bottom: 0;
}
.print_invoice .footer_invoice .signs_footer {
  width: 100%;
}
.print_invoice .footer_invoice .signs_footer .signs_invoice {
  text-align: center;
}

/*
.Invoice_Items{

    &._print{

        .Invoice_Items{
            width: 90%;
            margin: 0 auto;
            font-size: 8px;
            padding: 0;

            thead{

                tr{
                    text-align: left;
                    padding: 0;
                    th{
                    font-size:8px;
                        text-align: left;
                    padding: 0;

                    }
                }
            }
            tbody{
                tr{
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid #000;
                    td{
                    padding: 0;
                    height: 12px;
                    font-size: 8px;

                    &.subtotal{
                        text-align:right;
                    }
                    }

                }
            }



        }
    }
}

*//*# sourceMappingURL=Style.css.map */