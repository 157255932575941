.Transaction_wrapper {
  border-radius: 5px;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  display: grid;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
}
.Transaction_wrapper .Transaction_title {
  border-bottom: 0.5px solid rgba(10, 104, 135, 0.1529411765);
}
.Transaction_wrapper .Transaction_data {
  margin: 0 auto;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 0.5fr;
  grid-template-areas: "Transaction_from" "transaction_to" "reference_Transaction" "Transaction_submission";
  grid-gap: 15px;
}
.Transaction_wrapper .Transaction_data .Transaction_from {
  grid-area: Transaction_from;
  border-radius: 5px;
  border: 1px solid rgba(20, 135, 10, 0.4862745098);
  padding: 15px;
}
.Transaction_wrapper .Transaction_data .transaction_fields {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}
.Transaction_wrapper .Transaction_data .transaction_fields input {
  border: none;
  border-bottom: 1px solid grey;
}
.Transaction_wrapper .Transaction_data .transaction_to {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(135, 10, 10, 0.4862745098);
  grid-area: transaction_to;
}
.Transaction_wrapper .Transaction_data .Transaction_submission {
  grid-area: Transaction_submission;
}

.transaction-fields {
  display: grid;
  border-radius: 15px;
  max-width: 300px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}
.transaction-fields input {
  border: none;
  border-bottom: 1px solid grey;
}

.transaction-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "from to" "reference amount" "submit submit";
}
.transaction-container .to {
  grid-area: to;
}
.transaction-container .from {
  grid-area: from;
}
.transaction-container .reference {
  grid-area: reference;
}
.transaction-container .amount {
  grid-area: amount;
}
.transaction-container .submit {
  grid-area: submit;
}

.ledger-select-list {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ledger-select-list .LedgerSelect {
  margin: 15px 0;
  border: 1px solid rgba(20, 135, 10, 0.4862745098);
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: rgba(20, 135, 10, 0.4862745098);
  cursor: pointer;
  list-style-type: none;
}
.ledger-select-list .LedgerSelect:hover {
  background-color: rgba(20, 135, 10, 0.4862745098);
  color: white;
}/*# sourceMappingURL=Style.css.map */