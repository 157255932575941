/* Styling for the product card */
.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #fff;
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s; /* Added opacity transition */
  background-color: #fff;
  opacity: 0; /* Initial opacity set to 0 */
}

/* Hover effect */
.product-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for the product details section */
.product-info h2 {
  margin-top: 0;
}

.product-info table {
  width: 100%;
  border-collapse: collapse;
}

.product-info th,
.product-info td {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.product-info th {
  text-align: left;
}

/* Styling for the purchase history section */
.purchase-history table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.purchase-history th,
.purchase-history td {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.purchase-history th {
  text-align: left;
}

/* Styling for the "No history available" message */
.no-history {
  color: #777;
  font-style: italic;
}

/* Remove list-style from tables */
table {
  list-style: none;
  padding: 0;
}

.product-card.open {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1; /* Make the card fully visible */
}

/* Keyframe animation for hover effect */
@keyframes cardHover {
  from {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  to {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
/* Styling for the product card */
.product-card:hover {
  animation: cardHover 0.2s forwards; /* Apply animation on hover */
}

.Arch_Title {
  text-align: center;
  font-weight: 600;
}/*# sourceMappingURL=Style.css.map */