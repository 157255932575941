

.Prodcut_Catelog_Export_Pdf_wrapper {
  &.hidden {
     display: none;
   }
   width: 100%;
  // max-width: 210mm;
   
   //display: grid;
  //margin: 15px 5px ;
 //  place-content: center;
   padding: 0;
   /* Set A4 page size */
   size: A4;


   
.Watermark {
  position: fixed; /* Fixed position to ensure it stays in the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.2; /* Adjust opacity to make it semi-transparent (0.0 - 1.0) */
  z-index: -1; /* Place watermark behind other content */
  pointer-events: none; /* Make sure the watermark doesn't interfere with interaction */
  
}


.Prodcut_Catelog_Export_Pdf {
  border-collapse: collapse;
  //border: 1px rgb(47, 0, 255) solid;
  margin: 0 auto ;
 width: 95%;
//background-color: #002cbe;
  tbody{

 
}
.Main_Productrow{
  page-break-inside: avoid;

  display: grid;
  grid-template-columns: .5fr 1fr;
  border: 1px solid #002cbe;
  page-break-inside: avoid;
  .Product_image{
    page-break-inside: avoid;
  }
  .Product_info{
    page-break-inside: avoid;
    width: 100%;
   // display: grid;
   
.data_row{
  border: 1px solid #011146;
  width: 100%;
  // display: grid;

  td {
    border: 1px solid #011146;
    white-space: pre-wrap;
    width: 78px;
    max-width: 78px;
    word-wrap: break-word;
  }
}
  }

  &.Title{
    background-color: #002cbe2f;
   // margin-top: 15px;
  }
}

 }
}


@media print {
  table {
      position: relative;
      top: 20px;
  }
}