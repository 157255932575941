@import "../../../styles/variables.scss";
.header{
    /*  display: grid;
      grid-template-columns: 1fr 1fr  1fr ;
      grid-template-rows: minmax(15px, 1fr) minmax(15px, 1fr) ;
      grid-template-areas: 
      "main main main"
      "search_ search_ search_"
      ;*/
  
     // width: 100%;
  
    background-color: $Background_Light;
       // background-color: transparent;
      box-shadow: $shadow;
  
      @media screen and (max-width: $mobileMax) {
          padding-right: 25px;
      }
      @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
          padding-right: 35px;
      }
      @media screen and (min-width: $desktopMin) {
          padding-right: 50px;
      }
  
      &-logo{
          object-fit: contain;
  
          width: 75px;
  
          @media screen and (max-width: $mobileMax) {
          }
          @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
          }
          @media screen and (min-width: $desktopMin) {
          }
      }
  .HeaderWrapper{
      width: 100%;
  
    max-width: 1900px;
    margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(150px, 1fr) minmax(150px, 5fr)   minmax(150px, 1fr) ;
      grid-template-areas:
      "logo  search_head  navheader";
 
      .search_head{
          display: grid;
          grid-area: search_head;
          width: 100%;
      }
      .navheader{
          display: grid;
          justify-content: end;
          grid-area: navheader;
  
  
        }
      .logo{

          grid-area: logo;  
          display: grid;
          //move to left
          justify-content: start;
  
      }
      .Header_Cart{
          grid-area: Header_Cart;
  
      }
     
     
  }
  position: -webkit-sticky;
  position: sticky;
  top: 0;
   display: grid;
   z-index: 100;
  }

  .RightNav{
    display: grid;
    grid-template-columns: 1fr 1fr  1fr 1fr;
    place-items: center;
    .CartCount {
      background-color: red;
      color: white;
      font-size: 0.8rem;
      padding: 0.1rem 0.5rem;
      border-radius: 50%;
      
      top: -0.5rem;
      right: -0.5rem;
      }
  }
  
  .header {
      /* your existing header styles */
    }
    
   /* .sticky {
     position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
     /*  additional styles for sticky header */
  //}

    
  @media screen and (max-width: 768px) {
    .HeaderWrapper {
      display: grid;
.search_header_wrapper{
  display: grid;
  place-content: center;
 // background-color: aqua;
}
//background-color: black;

    }
  }