.product_Arch{
   // z-index: 10;
    transition: 1s ease-in;
    border: 1px solid #0a68873b;
    padding: 10px;
    &:hover{
      //  background-color: #0a6887;


   // background-color: #0b549425;
  
    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a6887c5;
  
    z-index: 100;
  transition: .25s ease-out;
  
  scale: 1.01;
    }
    
    .prdcount{
background-color: #0a6887;
color: white;
border-radius: 50%;
max-width: 15px;
max-height: 20px;
display: grid;
place-content: center;

}
   // background-color: aqua;
    max-width: 250px;
    max-height: 250px;
    border-radius: 5px;
    margin: 5px 5px;
   font-size: 11px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr auto auto auto auto;

    grid-template-areas: 
    
    "image"
    "archtitlewrapper"
    "selected_product"
    "brands"
    "products";
    .selected_product{
      //background-color:red ;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-area: selected_product;

 
    }
    .brands{
      grid-area: brands;
     // background-color: #0a6887;
      max-width: 95%;
      margin: 0 auto;
    }

    .images{
        grid-area: image;
      overflow: hidden;
        background-color: white;
    }
.products{
   // background-color: aquamarine;
    max-height: 15px;
}
    .archtitlewrapper{
        grid-area: archtitlewrapper;

        display: grid;

        grid-template-columns: 1fr;
        .title_Product{
            
            //background-color: red;
           // color: black;
            font-size: 14px;
            color: #0a6887;
        background-color: white;
          font-weight: 600;
        }
        .title{
            
            z-index: unset;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: 
            "titlename"
            "ArcCode";
            .titlename{
                grid-area: titlename;
            }
            .ArcCode{
            grid-area: ArcCode;

            }
        }

    }
}
.ProductScroll{
    margin: 0 auto;

    &._Revised_Arch{
            
        margin: 0 auto;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 250px));
        grid-auto-rows: minmax(250px, 250px);
        justify-content: center; /* Center align grid items horizontally */

    }
}


@media screen and (max-width: 768px) {
    .ProductScroll{
    &._Revised_Arch{
   // background-color: aquamarine;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    grid-auto-rows: minmax(250px, 250px);
  
    }
  }}
  
  
  @media screen and (max-width: 290px) {
    .ProductScroll{
    &._Revised_Arch{
   // background-color: rgb(176, 127, 255);
    grid-auto-rows: minmax(250px, 250px);

    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  
    }
  }}
  
  
  .ArcH_Product_Brand_Tabs{
    //background-color: #0a6887;
    font-size: 9px;
    color: #0a6887;
max-width: 200px;
  .MuiTabIndicator-root {
      background-color: #85870a;
    }
    .MuiTab-root{
    font-size: 9px;
//background-color: aqua;
padding: 0;
margin: 0;  
font-weight: 800;

}
    
    .MuiTab-root .Mui-selected
  {
    color: #0a6887;
    font-size: 10px;
font-weight: 800;
  }
  }