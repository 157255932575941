.Transaction_wrapper{
    //background-color: aliceblue;
    border-radius: 5px;
    border: .5px solid #0a688727;
    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);


    display: grid;
    width: 95%;
    max-width: 1200px;

    margin: 0 auto;
    padding: 15px;

    .Transaction_title{
        border-bottom: .5px solid #0a688727;
    }

    .Transaction_data{
        margin: 0 auto;
        display: grid;
        width: 100%;

        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr .5fr;

        grid-template-areas: 
        "Transaction_from"
        "transaction_to"
        "reference_Transaction"
        "Transaction_submission";
        grid-gap: 15px;

.Transaction_from{
    grid-area: Transaction_from;
    border-radius: 5px;
    border: 1px solid #14870a7c;
    padding: 15px;
 
}
.transaction_fields{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    input{
        border: none;
        border-bottom: 1px solid grey;
    }
}
.transaction_to{
    padding: 15px;

    border-radius: 5px;
    border: 1px solid #870a0a7c;

   

    grid-area: transaction_to;
}
.Transaction_submission{
   // background-color:bisque;


    grid-area: Transaction_submission;
}
    }

}





  .transaction-fields{

    display: grid;
    input{
        border: none;
        border-bottom: 1px solid grey;
    }
    border-radius: 15px;
    max-width: 300px;

    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  }

  .transaction-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 
    "from to"
    "reference amount"
    "submit submit"
    ;
    .to{
        grid-area: to;
    }
    .from{
        grid-area: from;
    }
.reference{
    grid-area: reference;
}
.amount{
    grid-area: amount;
}
    .submit{
        grid-area: submit;
    }


   
    
  }

  .ledger-select-list{
    text-align: left;
    width: 100%;
    margin: 0 auto;
    height: 150px;
    overflow-y: auto;
    overflow-x:hidden;
    .LedgerSelect{
    margin: 15px 0 ;
      border: 1px solid #14870a7c;
      border-radius: 5px;
      padding:  5px 5px 5px 5px ;
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      color: #14870a7c;
      cursor: pointer;
     
      list-style-type: none;
      &:hover{
        background-color:#14870a7c;
        color: white;
      }

     
    }
  }