@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
/* Modal Overlay */
.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

/* Modal Content */
.ReactModal__Content {
  overflow: auto;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  border: none;
  border-radius: 8px;
  padding: 20px;
  max-width: 90wv;
  width: 90%;
  max-height: 80vh;
  margin-top: 50px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal Close Button */
.CategoryMode button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
}

/* Modal Header */
.CategoriesList h3 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

/* Modal Search Bar */
.CategoriesList .controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.CategoriesList .Search_bar {
  flex: 1;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.CategoriesList .refresh {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
}

/* Modal Category List */
.Categorylist_item_Wrapper {
  display: grid;
  grid-gap: 10px;
}

.Categorylist_item {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.Categorylist_item.Model {
  border-color: #00c479;
}
.Categorylist_item.Category {
  border-color: #0077ff;
}
.Categorylist_item.Brand {
  border-color: #ff0000;
}

.Categorylist_item._selected {
  background-color: rgba(54, 54, 54, 0.137);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

.Categorylist_item img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.Count {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Modal "End of Categories" item */
.Categorylist_item:last-child {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* Loading Spinner */
.LoadingSpinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 2px solid #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.CategoriesList {
  width: 80%;
  margin: 5px auto;
  height: 100vh;
}
.CategoriesList .controls {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
}
.CategoriesList .controls button.refresh {
  color: red;
}
.CategoriesList .Search_bar {
  border: none;
  width: 80%;
  border-radius: 0px;
  text-align: center;
  margin: 10px auto;
  display: grid;
  border-bottom: 2px solid #0a6887;
}
.CategoriesList h3 {
  color: #0a6887;
  text-align: center;
  font-weight: 800;
}
.CategoriesList::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.CategoriesList::-webkit-scrollbar-thumb {
  border-radius: 0px;
}
.CategoriesList .Categorylist_item_Wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Automatic column sizing */
  grid-auto-rows: auto; /* Automatic row sizing */
  gap: 10px; /* Optional: Add space between items */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  height: auto; /* Optionally, set a fixed height or remove it for natural height */
}
.CategoriesList .Categorylist_item {
  margin: 18px 0px;
  width: 90%;
  margin: 10px auto;
  max-width: 300px;
  max-height: 250px;
  border-radius: 5px;
  display: grid;
  padding: 5px;
  grid-template-columns: 0.1fr 1fr 1fr;
  grid-template-areas: "CategoryLogo CategoryName CategoryName" "FavoriteIcon Count Count";
  transition: border-color 0.2s ease-in-out;
}
.CategoriesList .Categorylist_item._selected {
  transition: border-color 0.3s ease-in-out;
}
.CategoriesList .Categorylist_item .FavoriteIcon {
  grid-area: FavoriteIcon;
}
.CategoriesList .Categorylist_item .CategoryName {
  grid-area: CategoryName;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}
.CategoriesList .Categorylist_item .CategoryLogo {
  grid-area: CategoryLogo;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.CategoriesList .Categorylist_item .CategoryLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain; /* add this to ensure the image fits inside the container */
}
.CategoriesList .Categorylist_item .ProductCount {
  grid-area: ProductCount;
  font-weight: 600;
  font-size: 12px;
  color: gray;
  text-align: center;
}
.CategoriesList .Categorylist_item .ProductArchCount {
  grid-area: ProductArchCount;
  font-weight: 600;
  font-size: 12px;
  color: gray;
  text-align: center;
}
.CategoriesList .Categorylist_item .Count {
  grid-area: Count;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "ProductCount ProductArchCount";
  font-weight: 600;
  font-size: 12px;
  color: gray;
  text-align: center;
}

.Collection_Model {
  max-width: 90vw;
  border: solid 2px grey;
}

.Collection_Wrapper {
  display: grid;
  height: 100vh;
}
.Collection_Wrapper .menu_collection {
  display: grid;
  width: 100%;
  max-width: 450px;
  max-height: 100px;
  margin: 0 auto;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "menu  Export " "result_kpi  showing_ ";
}
.Collection_Wrapper .menu_collection .showing_ {
  z-index: 12;
}
.Collection_Wrapper .menu_collection .menu {
  display: grid;
  grid-area: menu;
  padding: 0;
  margin: 0;
}
.Collection_Wrapper .menu_collection .Export {
  display: grid;
  grid-area: Export;
  place-content: center;
}
.Collection_Wrapper .menu_collection .result_kpi {
  grid-area: result_kpi;
}

:root {
  --shine-degree: 120deg;
  --shine-color: rgba(131, 154, 255, 0.274);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all 0.65s ease-in-out;
}

.btn {
  position: relative;
  overflow: hidden;
  font-size: 1.1rem;
}
.btn:not(:last-child) {
  margin-bottom: 3rem;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--shine-effect);
}

.btn-anim::before {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  11.5044247788%, 100% {
    left: 100%;
    transition-property: left;
  }
}/*# sourceMappingURL=Style.css.map */