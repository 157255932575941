.Admin_Dashboard {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "List data_view";
}
.Admin_Dashboard .List {
  grid-area: List;
}
.Admin_Dashboard .List .Item {
  width: 80%;
  max-width: 200px;
  margin: 5px auto;
  border-radius: 5px;
  height: 50px;
  max-height: 75px;
  display: grid;
  text-align: left;
}
.Admin_Dashboard .List .Item._selected {
  border: 1px solid red;
}
.Admin_Dashboard .List .Item .Item_title {
  display: grid;
  place-content: center;
}
.Admin_Dashboard .data_view {
  grid-area: data_view;
  width: 100%;
  margin: 0 auto;
}

.tabs_side {
  display: grid;
  margin-top: 10px;
  width: 90%;
  max-width: 450px;
}
.tabs_side .MuiTabs-indicator {
  background-color: #0a6887; /* Change this to your desired color */
}
.tabs_side .tab {
  width: 95%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 5px;
  height: 50px;
  max-height: 75px;
  display: grid;
  cursor: pointer;
  font-weight: 800;
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
}
.tabs_side .tab .MuiButtonBase-root {
  text-align: left;
}
.tabs_side .tab:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
  color: #0a6887;
}
.tabs_side .tab._selected {
  color: #0a6887;
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid #0a6887;
  background-color: rgba(11, 84, 148, 0.1450980392);
  scale: 1.01;
  z-index: 100;
}

._Table_Custom {
  border-spacing: 0;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0;
}
._Table_Custom ._Table_Custom_Row {
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  transition: 1s ease-in;
  border: none;
  border: 14px solid rgba(10, 104, 135, 0.1529411765);
  border-radius: 15px;
  font-weight: lighter;
  font-size: 14px;
}
._Table_Custom ._Table_Custom_Row th {
  padding: 0;
  margin: 0;
}
._Table_Custom ._Table_Custom_Row td {
  padding: 0;
  margin: 0;
}
._Table_Custom ._Table_Custom_Row a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
._Table_Custom ._Table_Custom_Row:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
._Table_Custom ._Table_Custom_Row:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
._Table_Custom ._Table_Custom_Row.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}/*# sourceMappingURL=Style.css.map */