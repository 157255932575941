.paymentmode {
  display: grid;
  gap: 10px;
  margin-bottom: 10px;
  max-width: 90%;
}

.paymentModeBtn {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  max-height: 75px;
}

.creditterms {
  max-height: 75px;
}

.paymentModeBtn.selected {
  background-color: #ccc;
}

.creditterms select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}/*# sourceMappingURL=Style.css.map */