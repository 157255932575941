@import '../../../../styles/variables';

.voucherView{
    width: 100%;
margin: 0 auto;
    .pagination-bar{
        margin: 0 auto;
        display: grid;
        place-content: center;
    }
}

.Voucher_view_singular{
    
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-template-rows: 1fr 1fr;

    grid-template-areas: 
    "interactions voucherdetails"
    "voucheritems voucheritems"
    ;
    .interactions{
      //  background-color: aqua;
        grid-area: interactions;
    }
    .voucherdetails{
     //   background-color: azure;
        grid-area: voucherdetails;
    }
    .voucheritems{
     //   background-color: aquamarine;
        grid-area: voucheritems;
    }
}

.printvoucher{

    &.hidden{
        display: none;
    }

margin: 0 auto;
   width: 100%;
display: grid;

.letterhead{
   // background-color: antiquewhite;
    margin: 20px;
    max-height: 100px;
}

.voucherbody{
  //  background-color: aquamarine;
  .voucherdetails{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-template-rows: 1fr 1fr ;
        margin: 0 auto;
        width: 90%;
    ._field{
        font-size: 11px;
        font-weight: 800;
        margin: 5px 0;
        text-align: left;
    }
  }
    .details{

       
         
          
        margin: 5px 0;
       // background-color: rgb(23, 168, 168);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        text-align: center;
        .Title{
            font-weight: 800;
            border-bottom: 2px solid black;
            width: 80%;
            margin: 0 auto;
            font-size: 11px;
        }
        ._user{
            font-size: 10px;
            font-weight: 800;
        }
        ._Date{
         //   background-color: bisque;
            font-size: 10px;

        }
    }
    .voucheritems_print{
        display: grid;
        margin: 0 auto;
        width: 100%;
        font-size: 11px;
        tbody{
            font-size: 11px;
            tr{
            border-bottom: 2px solid black;
            }
            }
        }
        .printtablevoucheritems{
            width: 95%;
            margin: 0 auto;
        }
    }
}

.footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    img {
        width: 80%;
    text-align: center;
        margin: 0 auto;
      }
  }
