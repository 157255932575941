.dots-animation {
  display: inline-block;
  animation: dots 1s infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: none;
  }
  40% {
    color: #000;
    text-shadow: none;
  }
  60% {
    text-shadow: 0 0 4px #000;
  }
}
.dot {
  font-size: inherit;
}/*# sourceMappingURL=Style.css.map */