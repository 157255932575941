.controls-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 340000;
}

.controls-modal-content {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 80vw;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 350000;
  display: grid;
}
.controls-modal-content.dark {
  background-color: #333333;
  color: #fff;
}

.controls-modal-close {
  margin-top: 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.controls-modal-close:hover {
  background-color: #d32f2f;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.controls-icon {
  transition: transform 0.3s;
}

.controls-icon.rotate-animation {
  animation: rotate 2s infinite linear;
}

.controls-icon.bounce-animation {
  animation: bounce 0.5s infinite;
}

.controls_Tabs {
  margin: 0 auto;
}
.controls_Tabs .MuiTabs-root {
  background-color: #f2f2f2;
}
.controls_Tabs .MuiTabs-root .Mui-selected {
  color: #ff0000;
  background-color: #fff;
}
.controls_Tabs .MuiTabs-root .MuiTab-root {
  color: #000;
  font-weight: 600;
}
.controls_Tabs .MuiTabs-root .MuiTab-root:hover {
  color: #ff0000;
}
.controls_Tabs.dark {
  background-color: #333333;
  color: #fff;
}
.controls_Tabs.dark .MuiTabs-root {
  background-color: #333333;
  color: #fff;
}
.controls_Tabs.dark .MuiTabs-root .Mui-selected {
  color: #ff0000;
  background-color: #2e2e2e;
}
.controls_Tabs.dark .MuiTabs-root .MuiTab-root {
  color: #fff;
  font-weight: 600;
}
.controls_Tabs.dark .MuiTabs-root .MuiTab-root:hover {
  color: #ff0000;
}/*# sourceMappingURL=styles.css.map */