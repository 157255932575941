@import '../../../../styles/variables';

.voucher_View_Row{
width: 80%;
box-shadow: $shadow;
margin: 10px auto;
border-bottom: grey 1px solid;

    display: grid;

    grid-template-columns: 1fr .2fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr ;

    grid-template-areas: 
    "Voucer_Date voucher_Id invoiceNumber   payee Paymentmode VoucherTotal"
    ;
    .voucher_Id{
        grid-area: voucher_Id;
    }
.Voucer_Date{
    grid-area: Voucer_Date;
}
.VoucherTotal{
    grid-area: VoucherTotal;
    text-align: right;
}
.Paymentmode{
    grid-area: Paymentmode;
}
.payee{
    grid-area: payee;
}



&.verified{
//border-bottom: $Primary-Royal-Blue 3px solid;

background-color: rgba($Primary-Royal-Blue, 0.3);

}
&.approved{
background-color: rgba($Primary-Green, 0.3);

   // border-bottom: $Primary-Green 3px solid;

}
&.declined{
    background-color: rgba(255, 0, 0, 0.281);
}

}
