.product_Arch {
  transition: 1s ease-in;
  border: 1px solid rgba(10, 104, 135, 0.231372549);
  padding: 10px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr auto auto auto auto;
  grid-template-areas: "image" "archtitlewrapper" "selected_product" "brands" "products";
}
.product_Arch:hover {
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.7725490196);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.product_Arch .prdcount {
  background-color: #0a6887;
  color: white;
  border-radius: 50%;
  max-width: 15px;
  max-height: 20px;
  display: grid;
  place-content: center;
}
.product_Arch .selected_product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-area: selected_product;
}
.product_Arch .brands {
  grid-area: brands;
  max-width: 95%;
  margin: 0 auto;
}
.product_Arch .images {
  grid-area: image;
  overflow: hidden;
  background-color: white;
}
.product_Arch .products {
  max-height: 15px;
}
.product_Arch .archtitlewrapper {
  grid-area: archtitlewrapper;
  display: grid;
  grid-template-columns: 1fr;
}
.product_Arch .archtitlewrapper .title_Product {
  font-size: 14px;
  color: #0a6887;
  background-color: white;
  font-weight: 600;
}
.product_Arch .archtitlewrapper .title {
  z-index: unset;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "titlename" "ArcCode";
}
.product_Arch .archtitlewrapper .title .titlename {
  grid-area: titlename;
}
.product_Arch .archtitlewrapper .title .ArcCode {
  grid-area: ArcCode;
}

.ProductScroll {
  margin: 0 auto;
}
.ProductScroll._Revised_Arch {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 250px));
  grid-auto-rows: minmax(250px, 250px);
  justify-content: center; /* Center align grid items horizontally */
}

@media screen and (max-width: 768px) {
  .ProductScroll._Revised_Arch {
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    grid-auto-rows: minmax(250px, 250px);
  }
}
@media screen and (max-width: 290px) {
  .ProductScroll._Revised_Arch {
    grid-auto-rows: minmax(250px, 250px);
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  }
}
.ArcH_Product_Brand_Tabs {
  font-size: 9px;
  color: #0a6887;
  max-width: 200px;
}
.ArcH_Product_Brand_Tabs .MuiTabIndicator-root {
  background-color: #85870a;
}
.ArcH_Product_Brand_Tabs .MuiTab-root {
  font-size: 9px;
  padding: 0;
  margin: 0;
  font-weight: 800;
}
.ArcH_Product_Brand_Tabs .MuiTab-root .Mui-selected {
  color: #0a6887;
  font-size: 10px;
  font-weight: 800;
}/*# sourceMappingURL=Style.css.map */