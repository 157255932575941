.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
  width: 80vw;
  height: 80vh;
  max-width: 1920px;
  max-height: 1080px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin: 50px auto 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
}

.modal-body {
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: #000;
}

.selected-files {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
}

.file {
  position: relative;
}

.file img {
  width: 100%;
  max-width: 200px;
  height: auto;
  cursor: pointer;
}

.file button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-footer button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.edit-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.edit-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-modal-content h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.responsive-image-editor {
  width: 100%;
  height: 90vh;
}/*# sourceMappingURL=Style.css.map */