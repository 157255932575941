.orderlist-container {
  overflow-x: auto;
}
.orderlist-container table {
  width: 100%;
  border-collapse: collapse;
}
.orderlist-container table th,
.orderlist-container table td {
  padding: 10px;
}
.orderlist-container table th:first-child,
.orderlist-container table td:first-child {
  min-width: 200px;
}
.orderlist-container table th:nth-child(2),
.orderlist-container table td:nth-child(2) {
  min-width: 120px;
}
.orderlist-container table th:nth-child(3),
.orderlist-container table td:nth-child(3) {
  min-width: 160px;
}
.orderlist-container table th:nth-child(4),
.orderlist-container table td:nth-child(4) {
  min-width: 50px;
}
.orderlist-container table th:nth-child(5),
.orderlist-container table td:nth-child(5) {
  min-width: 100px;
}

.orderlist_Item {
  margin: 0 auto;
  width: 90%;
  border-radius: 5px;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
  grid-template-columns: 75px 75px 75px 350px 150px 250px 50px;
  grid-template-areas: "Urgent salesOrderId created_Date_Time customer_name total salesRep Status";
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  font-weight: lighter;
  font-size: 14px;
}
.orderlist_Item .Urgent {
  font-weight: 800;
}
.orderlist_Item:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.orderlist_Item:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.orderlist_Item .salesOrderId {
  grid-area: salesOrderId;
  display: grid;
}
.orderlist_Item .created_Date_Time {
  grid-area: created_Date_Time;
  display: grid;
}
.orderlist_Item .customer_name {
  grid-area: customer_name;
  display: grid;
}
.orderlist_Item .total {
  grid-area: total;
  display: grid;
}
.orderlist_Item .salesRep {
  grid-area: salesRep;
  display: grid;
}
.orderlist_Item .Status {
  grid-area: Status;
  display: grid;
}
.orderlist_Item a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.orderlist_Item.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}

/* Media query for mobile screens (adjust max-width as needed) */
@media screen and (max-width: 768px) {
  .orderlist_Item {
    grid-template-columns: 7.89% 7.89% 36.84% 15.79% 26.32% 5.26%;
  }
}
/* Media query for screens between 480px and 320px */
@media screen and (max-width: 480px) {
  .orderlist_Item {
    grid-template-columns: 20% 20% 30% 15% 15% 5%;
  }
}
/* Media query for screens smaller than 320px */
@media screen and (max-width: 320px) {
  .orderlist_Item {
    grid-template-columns: 30% 30% 30% 5% 5% 0%;
  }
}
/* Media query for screens with a minimum width of 280px and minimum height of 653px */
@media screen and (max-width: 280px) and (min-height: 653px) {
  .orderlist_Item {
    grid-template-columns: 40% 40% 10% 5% 5% 0%;
  }
}/*# sourceMappingURL=Style.css.map */