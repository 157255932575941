

  .home-tabs-container {
    display: grid;
    place-content: center;

    .home-tabs {
     border-bottom: 1px solid #ccc;
     overflow-x: auto;
     width: 100%;
      & .Mui-selected .MuiTabIndicator-root {
        background-color: #ff0000;
      }
    }
  
    .home-tab-label {
      text-transform: capitalize;
      font-weight: 800;
    }
  


    & .MuiTabs-indicator {
        background-color: #ff0000;
      }

    & .MuiTab-root.Mui-selected {
    color: #ff0000;
    
      }
  
   
  }

  .tabs-container_Invoice {

    max-width: 80%; /* or whatever value you want */
  }
