.controls-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 340000;

  }
  
  .controls-modal-content {
    background-color: #fff;
    padding: 20px;
    width: 80vw;
    max-width: 80vw;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 350000;

    display: grid;

    &.dark {
    background-color: #333333;
    color: #fff;
    }


  }
  
  .controls-modal-close {
    margin-top: 20px;
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .controls-modal-close:hover {
    background-color: #d32f2f;
  }


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.controls-icon {
  transition: transform 0.3s;
}

.controls-icon.rotate-animation {
  animation: rotate 2s infinite linear;
}

.controls-icon.bounce-animation {
  animation: bounce 0.5s infinite;
}




// Custom class for the Tabs component
.controls_Tabs {
  // Add custom styles here
margin: 0 auto;
  .MuiTabs-root {
    // Override MUI styles for the Tabs component
    // Example: Change background color
    background-color: #f2f2f2;

    // Custom color for the active Tab
    .Mui-selected {
      color: #ff0000; // Custom color for the text
      background-color: #fff; // Custom background color
    }

    // Custom color for the inactive Tabs
    .MuiTab-root {
      color: #000; // Custom color for the text
      font-weight: 600;

      &:hover {
        color: #ff0000; // Custom color on hover
      }
    }
  }


  &.dark{
    background-color: #333333;
    color: #fff;


    .MuiTabs-root {
      // Override MUI styles for the Tabs component
      // Example: Change background color
      background-color: #333333;
      color: #fff;

  
      // Custom color for the active Tab
      .Mui-selected {
        color: #ff0000; // Custom color for the text
        background-color: #2e2e2e; // Custom background color
      }
  
      // Custom color for the inactive Tabs
      .MuiTab-root {
        color: #fff;
        // Custom color for the text
        font-weight: 600;
  
        &:hover {
          color: #ff0000; // Custom color on hover
        }
      }
    }
  }
}
