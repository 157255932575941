.SalesOrderPage{
  //  background-color: aliceblue;
    width: 98%;
    margin: 0 auto;


   .Sales_order_View{
    border-radius: 15px;
    border: 1px solid $Primary;
   }


    ._Info{
     // background-color: aquamarine;
      width: 80%;
      border-radius: 15px;
    box-shadow: $shadow;
   // border: 1px solid $Primary;
    margin: 15px auto;
    padding: 15px;
&:hover{
 // border: 1px solid $Primary;
}

    ._Info__Order{
      display: grid;


      grid-template-columns: 2fr 1fr 1fr ;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
      "_CustomerName _Created_Date_Time _SalesRep_Name"
      "_OrderNumber . ."
      ;

      ._CustomerName{
        grid-area: _CustomerName;
        font-weight: 600;
        font-size: 15px;
      }
      ._Created_Date_Time{
        grid-area: _Created_Date_Time;
        font-weight: 600;
        font-size: 15px;
      }
      ._SalesRep_Name{
        grid-area: _SalesRep_Name;
        font-weight: 600;
        font-size: 15px;
      }
      ._OrderNumber{
        grid-area: _OrderNumber;
        font-weight: 600;
        font-size: 15px;
      }

    }






    .Customer{
      //  background-color: #39da1944;
        border-bottom: 2px solid $Primary-Theme-Green;
        grid-area: Customer;
        &.bad{
          color: red;
        border-bottom: 2px solid red;
    
        }
        &.Good{
          color: $Primary-Theme-Green;
        border-bottom: 2px solid $Primary-Theme-Green;
    
        }
      }

    
    }
}
.Order_Container_Main{
  border-radius: 15px;
  border: 1px solid $Primary;
  background-color: aqua;
}
.Order{
  //  margin: 20px;
    border-radius: 15px;
   // border: 1px solid $Primary;
    margin: 15px 0;
transition: all 0.3s ease-in-out;

    .OrderItems{
  //    background-color: #0274d86c;
      padding: 15px;
    }
}
.StateName{
  margin: 10px auto;
  font-size: 14px;
  text-align: center;
 // width: 150px;
font-weight: 900;
//border-bottom: 2px $Primary solid;
//border-radius: 5px;

}
.Orderinfo{
 // background-color: aqua;
 width: 80%;
 margin: 15px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
border-bottom: 3px $Primary solid;
  grid-template-areas:
  "Vchseries Orderid All_Order_Items_Status "
  ;
  .TaxType{
    grid-area: TaxType;
    border-bottom: 2px solid $Primary;
  }
font-size: 9px;
font-weight: 600;
  .Orderid{
   // background-color: #4909091f;
    grid-area: Orderid;
    margin: 0 auto;
    font-size: 14px;
  }

  .Vchseries{
   // background-color: #061bdd27;
    grid-area: Vchseries;
  }
  .Created_DateTime{
  //  background-color: #960d8b31;
    border-bottom: 2px solid $Primary;
    grid-area: Created_DateTime;
  }
  .Customer{
  //  background-color: #39da1944;
    border-bottom: 2px solid $Primary-Theme-Green;
    grid-area: Customer;
    &.bad{
      color: red;
    border-bottom: 2px solid red;

    }
    &.Good{
      color: $Primary-Theme-Green;
    border-bottom: 2px solid $Primary-Theme-Green;

    }
  }
  .BrokerName{
 //   background-color: #1f22c028;
    border-bottom: 2px solid $Primary;
    grid-area: BrokerName;
  }
  .GTotal{
  grid-area: GTotal;
  //  background-color: #ce08082a;
  border-bottom: 2px solid $Primary;
  
  }
.All_Order_Items_Status{
    grid-area: All_Order_Items_Status;
//  background-color: #cccccc27;
}



}
// StatusDropdown.module.scss

.Line_Item_approvalstate {
    display: inline-block;
    position: relative;
  }
  
  select {
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    &:focus {
      border-color: $Primary-Theme-Green;
    }
  
    option{
        &:hover {
            background-color: $Primary-Theme-Green;
          }
    }
  }
  
  // Add a custom arrow for the select dropdown
  .Line_Item_approvalstate::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: $Primary-Theme-Green;
  }
  @media screen and (min-width: 700px) {
    .Orderinfo{
   font-size:15px;
   .Orderid{
     font-size: 18px;
   }
    }
  }