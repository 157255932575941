.Transaction_Short {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  width: 100%;
}
.Transaction_Short .totals-section {
  border-radius: 15px;
  border: 1px rgb(0, 0, 0) solid;
  padding: 5px;
  height: -moz-min-content;
  height: min-content;
}
.Transaction_Short .totals-section:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  transition: 0.25s ease-out;
}
.Transaction_Short .Data_Draw_Chart {
  border-radius: 15px;
  border: 1px rgb(0, 0, 0) solid;
  padding: px;
  display: grid;
  width: 100%;
}
.Transaction_Short .Data_Draw_Chart:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  transition: 0.25s ease-out;
}

.Transaction_Stock_details {
  width: 100%;
  margin: 0 auto;
}

.Product_Stock_Info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Product_Stock_Info ._Table_Custom {
  width: 90%;
  margin: 0 auto;
  border-spacing: 0;
}
.Product_Stock_Info ._Table_Custom ._Table_Custom_Row {
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  transition: 1s ease-in;
  border: none;
  border: 14px solid rgba(10, 104, 135, 0.1529411765);
  border-radius: 15px;
  font-weight: lighter;
  font-size: 14px;
}
.Product_Stock_Info ._Table_Custom ._Table_Custom_Row a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.Product_Stock_Info ._Table_Custom ._Table_Custom_Row:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.Product_Stock_Info ._Table_Custom ._Table_Custom_Row:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.Product_Stock_Info ._Table_Custom ._Table_Custom_Row.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}

@media (max-width: 600px) {
  table {
    font-size: 12px;
  }
}
.in-stock {
  background-color: rgba(10, 104, 135, 0.4784313725);
  color: black;
}

.out-stock {
  background-color: rgba(37, 212, 66, 0.2);
  color: black;
}/*# sourceMappingURL=Style.css.map */