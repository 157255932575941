.ApprovedItems{
   // background-color: rgb(44, 219, 111);

}
.itemwrapper{
 // margin: 10px auto;
}
.StateName{
    color: $main-grey;
   // font-size: 18px;
   // border-bottom: 2px solid $Primary-Theme-Green;
    padding: 5px;
    margin: 15px;

}

.Payment_Type{
    color: $Primary-Theme-Green;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    border-bottom: 2px solid $Primary-Theme-Green;
    padding: 15px;

}
.Order_Items_Table{
    width: 100%;

}
tr.Line_Item {
  padding-bottom: 10px;
  margin-bottom: 10px;

}
.Order_Items_Table{
  display: grid;

}
.Line_Item_order{
  &.titles{
    background-color: $Primary-Theme-Green;

    color: white;
   // font-weight: 600;
  box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
 // font-size: 10px;
  }
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px); /* set a fixed width of 200px for each column */
 // box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
 // border-radius: 15px;

  font-weight: lighter;

grid-template-columns: 35px .1fr  3fr 1fr 1fr  1fr 1fr 1fr 1fr 1fr;
//grid-template-rows: 1fr;
   // border-bottom: 1px grey solid;
   //   font-weight: 600;
   font-size: 14px;
  // box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  // margin-bottom: 10px;
padding: 0;
max-height: 35px;
grid-template-areas:

"rowcount selector productName brand  Line_Item_Qty Line_Item_Discount listPrice price total_amount profit status"
;

.rowcount{
  grid-area: rowcount;
  //background-color: rgba(127, 255, 212, 0.377);
}

.Selector{
  grid-area: Selector;
  //background-color: rgba(0, 0, 255, 0.288);
}
.brand{
  grid-area: brand;
//background-color: rgba(138, 24, 9, 0.288);

  //background-color: rgba(0, 0, 255, 0.288);
}
.productName{
  grid-area: productName;
 //background-color: rgba(233, 236, 26, 0.288);

}
.Line_Item_Qty{
  grid-area: Line_Item_Qty;
 //background-color: rgba(255, 0, 0, 0.288);
}
.Line_Item_Discount{
  grid-area: Line_Item_Discount;
 //background-color: rgba(0, 255, 136, 0.288);
}
.qtydiscount{
  grid-area: qtydiscount;
 //background-color: rgba(200, 255, 0, 0.288);

}
.listPrice{
 //background-color: rgba(179, 255, 0, 0.288);
  grid-area: listPrice;
}
.price{
 //background-color: rgba(0, 255, 98, 0.288);
  grid-area: price;
}
.total_amount{
 //background-color: rgba(255, 187, 0, 0.288);
  grid-area: total_amount;
}
.profit{
  grid-area: profit;
 // background-color: rgba(255, 0, 157, 0.288);
}
.status{
  grid-area: status;
// background-color: rgba(255, 0, 0, 0.288);
}
}

// Define styles for each state
.Approved{
  // background-color: $approved-light;
  border-bottom: 1px $approved-dark solid;
  background-color: $approved-light;
}

/* Select the Tabs component */
.MuiTabs-root  {
  /* Add some space at the bottom of the Tabs component */
  margin-bottom: 16px;

}
.Orderitems_Filter_tabs{
  display: grid;
  margin: 0 auto;
  width: 80%;
  overflow: auto;
}
.MuiTabs-scroller {
  overflow: auto;
}

/* Select all the Tab components */
.orderApproved.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: $approved-dark;
  
}


/* Select all the Tab components */
.Pending.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: $pending-dark;
  
}
/* Select all the Tab components */
.ReadyToPack.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: $readyToPack-dark;
  
}
.Dispatch.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color:$dispatch-dark ;
}

.ReadyToDispatch.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color:$readyToDispatch-dark ;
  
}
.Declined.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color:$declined-dark ;
  
}
.Invoiced.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color:$invoiced-dark ;
  
}
.Refund.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color:$refund-dark ;
  
}
.All.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  
}

.ReadyToPack{
  // background-color: $readyToPack-light;
  background-color: $readyToPack-light;
  border-bottom: 1px $readyToPack-dark solid;
  
}

.ReadyToDispatch{
  // background-color: $readyToDispatch-light;
  background-color: $readyToDispatch-light;
  border-bottom: 1px $readyToDispatch-dark solid;
}

.Dispatch{
  // background-color: $dispatch-light;
  border-bottom: 1px $dispatch-dark solid;
  background-color: $dispatch-light;
  
}

.Declined{
  // background-color: $declined-light;
  border-bottom: 1px $declined-dark solid;
  background-color: $declined-light;

}

.Invoiced{
  // background-color: $invoiced-light;
  background-color: $invoiced-light;
  border-bottom: 1px $invoiced-dark solid;
}

.Refund{
  // background-color: $refund-light;
  background-color: $refund-light;
  border-bottom: 1px $refund-dark solid;
}
.Pending{
  background-color: $pending-light;
  border-bottom: 1px $pending-dark solid;
}
.pending{
  background-color: $pending-light;
  border-bottom: 1px $pending-dark solid;
}
.InvoiceData{
    background-color: #e2e2e2;
   // background-color: #25bd97;
   // border-radius: 5px;
    margin : 10px auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    

}

.Order_Items_Table{
    margin: 0 auto;
    width: 100%;
}

.Payment_Type_summery{
  color: $Primary-Blue;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  padding: 5px;
  border-bottom: 3px solid $Primary;
text-transform: uppercase;
  h2{
    text-decoration: none;
  }
}
.Order_Items_Payment_mode{
  //  border-radius: 15px;
    //box-shadow: $shadow;
    //border: 1px solid $Primary;
    width: 100%;
    margin: 15px auto;


  }
.Order_Items_Credit_Period{
    //border-radius: 15px;
   // box-shadow: $shadow_2;
    //border: 1px solid $Primary;
 //   width: 90%;
    margin: 15px auto;
&:hover{
 // border: 3px solid $Primary-Green;
}
  }

.ReadyToDispatch{
   // background-color: rgb(92, 235, 147);

}
.DeclinedItems{
    //background-color: rgba(255, 0, 0, 0.322);
}

.Titles_Cart_Line_Item{
    font-family: 'open sans';
    color: white;
    font-weight: 400;

    font-size: 10px;
    display: grid;
    background-color: $Primary-Theme-Green;
    width: 100%;


    grid-template-rows: 1fr  1fr 1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

margin: 0 auto;

    grid-template-areas:
        " Name Name  Name  Name  ACode "
        " Supplier Supplier Supplier   Brand Brand  "
        "Qty Discount List_Price   Price   Amount"
        ;


        &_Brand{

            //border-left: 1px solid $Primary-Theme-Green;
                 //   background-color: rgba(139, 7, 14, 0.3);
              // background-color: #3aca4d;
                    grid-area: Titles_Brand;
                        
                    }
                &_Supplier{
               //    background-color: rgba(132, 0, 255, 0.3);
                    grid-area: Titles_Supplier;    
            //border-left: 1px solid $Primary-Theme-Green;
                    
                }
               
                 margin: 0 auto;
            
                 &_Name{
          
                    grid-area: Titles_Name;
            }
            
                &_ACode{
            
                    grid-area: Titles_ACode;
            }
            
                &_Qty{
                    grid-area: Titles_Qty;
                    text-align: right;
                    padding: 2px 0;
                    
                }
            
                &_List_Price{
                    text-align: right;
                    grid-area: Titles_List_Price;
                    
            }
                &_Discount{
                    grid-area: Titles_Discount;
                    text-align: right;
                    padding: 2px 0;
            
            }
            
            &_Price{
                    grid-area: Titles_Price;
                    text-align: right;
                    padding: 2px 0;
            
                
            }
            .Titles_Cart_Line_Remove{
                text-align: center;
                grid-area: Titles_Remove;

        
            }
                &_Amount{
                    text-align: right;
                    grid-area: Titles_Amount;
                    padding: 2px 0;
            
                }


}
.Cart_Line_Item{

   
    display: grid;
    
       width: 100%;



    grid-template-rows: 1fr  1fr 1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

//border-radius: 5px;
//border: 3px solid #359c8d36;
margin: 0 auto;
//padding: 5px;
font-weight: 400;
font-size: 10px;
    grid-template-areas:
        " Name Name  Name  Name  ACode "
        " Supplier Supplier Supplier   Brand   Brand"
        "Qty Discount List_Price   Price   Amount"
        ;


        font-size: 9px;

        &_Brand{
//border-left: 1px solid $Primary-Theme-Green;
    //   background-color: rgba(139, 7, 14, 0.144);
  // background-color: #3aca4d;
        grid-area: Brand;
            
        }
    &_Supplier{
     // background-color: rgba(132, 0, 255, 0.144);
        grid-area: Supplier;    
//border-left: 1px solid $Primary-Theme-Green;
        
    }
   
     margin: 0 auto;

     &_Name{
//border-left: 1px solid $Primary-Theme-Green;

        // background-color: rgba(255, 0, 13, 0.548);
      //   background-color: #0a688775;
        grid-area: Name;
}

    &_ACode{
//border-left: 1px solid $Primary-Theme-Green;

        grid-area: ACode;
      //  background-color: rgba(61, 71, 207, 0.767);
}

    &_Qty{
        grid-area: Qty;
       // background-color: rgba(230, 197, 10, 0.5);
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;
        
    }

    &_List_Price{
      //   background-color: rgba(255, 153, 0, 0.733);
      //   background-color: #e616c023;
        text-align: right;
        grid-area: List_Price;
//border-left: 1px solid $Primary-Theme-Green;
        
}
    &_Discount{
        grid-area: Discount;
       //  background-color: rgba(204, 169, 12, 0.144);
      //   background-color: #80ced77c;
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;

}

&_Price{
        grid-area: Price;
    // background-color: rgba(14, 171, 219, 0.3);
      //  background-color: #25bd97;
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;

    
}
    &_Amount{
        text-align: right;
        grid-area: Amount;
        padding: 2px 0;
     //    background-color: rgba(43, 255, 0, 0.3);
// border-left: 1px solid $Primary-Theme-Green;

    }
   
}
   
//media query larger than 700 px 
@media screen and (min-width: 700px) {
.Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns:.1fr    3fr 1fr .5fr 1fr 1fr 1fr .5fr minmax(250px,1fr)   ;
   font-size: 17px;
    grid-template-areas:
        "rowcount Name Brand  Qty List_Price  Discount Price  Amount Profitablity approvalstate"
       ;

     
}

.Titles_Cart_Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns:     3fr 1fr 1fr 1fr 1fr 1fr 1fr   ;
   font-size: 13px;
    grid-template-areas:
        "  Titles_Name Titles_Brand Titles_Qty Titles_List_Price Titles_Discount Titles_Price Titles_Amount "
       ;
     
}
.Cart_Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns:    3fr 1fr 1fr 1fr 1fr 1fr 1fr   ;
    font-size: 13px;

    grid-template-areas:
        "  Name Brand Qty List_Price Discount Price Amount"
       ;
     
}
}


.line-product-summary-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .Modal_Summary{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "table notes_List"
        "profit profit";
    

        .table{
          grid-area: table;
        }
        .notes_List{
          grid-area: notes_List;
        }
    .profit{
      grid-area: profit;
    }
      }
  }
  
  .line-product-summary-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 90vw;
    max-height: 90vh;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .line-product-summary-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  

  
  
  .icon-cell {
    position: relative;
  }
  
  .warning-sign {
   //  animation: warningAnimation 1.5s infinite ease-in-out;
  }
  
  .icon {
    font-size: 1.5rem;
  }
  
  .note-icon {
    color: orange;
   // outline: 1px solid rgb(44, 44, 44);
    // position: absolute;
    // top: 50%;
   //  left: 50%;
    // transform: translate(-50%, -50%);
    font-size: 1rem;
     //animation: noteAnimation 2s infinite ease-in-out;
  }
  
  @keyframes warningAnimation {
    0%,
    100% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }
  
  @keyframes noteAnimation {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }


  .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    padding: 0;
  }