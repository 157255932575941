.Note_ui {
  display: grid;
  height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
  
  .Note {


    a{
      color: black;
        font-weight: lighter;
        text-decoration: none;
      }
  
      font-weight: lighter;
  font-size: 14px;
  place-content: center;
                margin: 0 auto;
                  width: 100%;
                  border-radius: 15px;
                 // box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
                 padding: 5px 0;
  
                 z-index: 10;
  transition: 1s ease-in;
  border: .5px solid #0a688727;
  
                  &:hover{
                      background-color: #0b54946c;
  
                      background-color: #0b549425;
                      box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
                  
                      box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
                      border: 1px solid #0a688727;
                  
                      z-index: 100;
                  transition: .25s ease-out;
                  
                  scale: 1.01;
  
                  &.Title{
                      background-color: #0a6887;
                      border-radius: 5px;
                      color: white;
                      scale: 1;
                      transition: none ;
              
              }
                    }
                  &.Title{
                      background-color: #0a6887;
                      border-radius: 5px;
                      color: white;
  
                      
  }
              



  padding: 10px;
  margin: 5px auto;
    height: 75px;
    max-height: fit-content;
    width: 300px;
    max-width: 100%;

   // box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
    overflow: hidden;
  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "Message"
      "Info";
  
    .Info {
    // background-color: aqua;
    max-height: 25px;
      grid-area: Info;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "note_Creator created_Datetime";

    }
  
    .note_Creator {
    //   background-color: rgb(72, 255, 0);

      max-height: 25px;

      font-weight: 800;
      grid-area: note_Creator;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .created_Datetime {
    //  background-color: rgb(76, 0, 255);
max-height: 25px;
      font-weight: 800;
      grid-area: created_Datetime;
      text-align: right;
      opacity: .5;
    }
  
    .Message {
    //   background-color: rgb(119, 0, 255);

      grid-area: Message;
      padding: 10px;
      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      
    }



  }

  .note_Creator{
    width: 150px;
    margin: 0 auto;
    //background-color: rgb(155, 127, 255);

    font-weight: 800;
    

  }

}