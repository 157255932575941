.tabs_Current_Asset {
  border-top: solid 2px grey;
  padding-top: 10px;
}
.tabs_Current_Asset .MuiTabs-indicator {
  background-color: transparent; /* Change this to your desired color */
}
.tabs_Current_Asset .tab {
  font-size: 12px;
  width: 95%;
  max-width: 200px;
  margin: 0 auto;
  border-radius: 5px;
  height: 50px;
  max-height: 75px;
  display: grid;
  cursor: pointer;
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(56, 135, 10, 0.1529411765);
}
.tabs_Current_Asset .tab .MuiButtonBase-root {
  text-align: left;
}
.tabs_Current_Asset .tab:hover {
  background-color: rgba(56, 135, 10, 0.1529411765);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(135, 10, 10, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
  color: #38870a;
}
.tabs_Current_Asset .tab._selected {
  color: #38870a;
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid #38870a;
  background-color: rgba(56, 135, 10, 0.1529411765);
  scale: 1.01;
  z-index: 100;
}/*# sourceMappingURL=Style.css.map */