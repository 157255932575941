.button_one{
     background-color: $Primary-Blue-Background;
       color: rgb(0, 0, 0);
       border: 2px solid $Primary-Blue;
       border-radius: 5px;
       width: 90%;
       max-width: 250px;
       max-height: 50px;
       margin: 10px auto;
       padding: 15px;
       &:hover{
           background-color: $Primary-Blue;
           color: white;
       }
&.danger{
    background-color: $danger;
    color: white;
    border: 2px solid $danger;
    &:hover{
      background-color: $danger;
      color: white;
    }
}

&.Place_invoice{

  border: 2px solid $Primary-Theme-Green;
  background-color: $Primary-Blue-Background;
color:$Primary-Theme-Green;
  &:hover{
    background-color: $Primary-Theme-Green;
    color: white;
  }
}

&.sendicon{
    width: 50px;
    max-width:50px;
}

}