@import "../../../../styles/variables";

.Modal_parent{
    background-color: #ff0d0d;   
    width:100%;

}
  
.modal-content input {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
/*
.modal-content button {
    font-size: 18px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 999;
  }

  
  .modal-content {
.Cart_title{
grid-area: Cart_title;
  background-color: $Primary-Theme-Green;
  max-width: 80%;
  width: 80%;
  border-radius: 5px;
  color: white;

margin:  0 auto;
display: grid;
place-content: center;
}
    background-color: #ffffff;
height: auto;
    width:90%;
    max-width: 90vw;
    max-height: 90vh;
    display: grid;
    /* ... */
    box-shadow: $shadow;
    border-radius: 10px;
    border: 2px solid $Primary-Royal-Blue;
grid-gap: 15px;
padding: 20px;
margin: 0 auto;

    grid-template-columns: 1fr 1fr;
   grid-template-rows:45px 200px  75px 75px 75px 50px 50px 50px  50px;
    grid-template-areas: 
    "Cart_title Cart_title"
    "Productincartmodal Productincartmodal"
    "Quantity Quantity"
    "discount discount"
    "Nett_Price Nett_Price"
    "note note"
    "paymentmode paymentmode"
    "Total Total"
    "addtocart addtocart"

    ;
.addtocart{
  margin: 0 auto;
  width: 90%;
}
    .Nett_Price{
      grid-area: Nett_Price;
    }
.title{
    grid-area: title;
  width: 90%;

}
    input{

      border: none;
      border-bottom: 1px solid $Primary-Theme-Green;
      border-radius: 0;
      padding: 5px;
      margin: 5px;
      font-size: 10px;
  
  }

    .Productincartmodal{


       width:100%;
        margin: 0 auto;
    display: grid;
        grid-area: Productincartmodal;
    }
    .Quantity{
        grid-area: Quantity;    
        width: 90%;
        margin: 0 auto;
    display: grid;
    place-content: center;
   // background-color: #a9e4cb;
    }
    .discount{
    display: grid;
    width: 90%;
margin: 0 auto;
    place-content: center;
 // background-color: #e2a9e4;
        grid-area: discount;
    }
    .paymentmode{
    display: grid;
  //  background-color: #e4a9b8;
        grid-area: paymentmode;
        width: 90%;
        margin: 0 auto;
    }
    .creditterms{
    display: grid;
  //  background-color: #c5e4a9;
        grid-area: creditterms;
    }
    .note{
    display: grid;
  //  background-color: #e4e0a9;
        grid-area: note;
        width:90%;
       margin: 0 auto;
       height: auto;
       max-height: 20px;
    }
    .addtocart{
    display: grid;
        grid-area: addtocart;
   
            /*button{
    //width:90%;

                background-color: #359c8d;
                color: rgb(255, 255, 255);
            }*/
    }
    .closecartmodalbtn{
        margin: 0 auto;
        width:100%;


    grid-area: closecartmodalbtn;


     
    }
    .Total{
        margin: 0 auto;
    display: grid;
        grid-area: Total;
      font-size: 15px;
      font-weight: 600;

      }
  }

  
  @media (min-width: 767px) {
    .modal-content {
        width: 90%;
      /* ... */
      display: grid;
     // height: auto;
      max-height: 80vh ;
      grid-template-columns: 1fr 1fr 1fr   ;
      grid-template-rows:40px 100px 150px  100px ;
grid-gap: 15px;
padding: 15px;
      grid-template-areas: 
      "Cart_title Cart_title  Cart_title    "
      "Productincartmodal Productincartmodal  Productincartmodal     "
      "Quantity  discount       Nett_Price"
      " note  paymentmode     addtocart  "
      "Total        Total Total     "
     
      ;
     
      .Productincartmodal{

width: 100%;
height: 10%;


      }
     
    }
  
    .modal-content input {
      font-size: 20px;
      padding: 20px;
      margin-bottom: 20px;
    }
  
    .modal-content button {
      font-size: 24px;
      padding: 20px;
    }
  }
  
  .paymentmode {
    display: flex;
    gap: 5px;
  }
  
  .paymentmode button {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 1em;
    color: #ffffff;
    background-color: #bbbbbb;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .paymentmode button.selected {
    background-color: #007bff;
  }
  




  .quantity-input,
  .Nett_Price-input,
  .discount-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quantity-control,
  .Nett_Price-control,
  .discount-control {
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  
  .decrement-button,
  .increment-button,
  .Nett_Price-control button,
  .discount-control button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    outline: none;
  }
  
  .decrement-button {
    margin-right: 4px;
  }
  
  .increment-button {
    margin-left: 4px;
  }
  
  .quantity-field,
  .Nett_Price-field,
  .discount-field {
    border: none;
    width: 40px; /* Adjust the width as needed */
    text-align: center;
  }
  
  .Nett_Price-input,
  .discount-input {
    margin-bottom: 1rem;
  }
  
  /* Media query for screens smaller than 600px */
  @media screen and (max-width: 600px) {
    .quantity-field,
    .Nett_Price-field,
    .discount-field {
      width: 100%; /* Full width on small screens */
    }
    
    .quantity-input,
    .Nett_Price-input,
    .discount-input {
      flex-direction: row; /* Change direction to row for better mobile layout */
    }
  
    .decrement-button,
    .increment-button,
    .Nett_Price-control button,
    .discount-control button {
      padding: 8px; /* Increase padding for better touch targets on mobile */
    }
    
    .Nett_Price-input,
    .discount-input {
      margin-bottom: 0.5rem; /* Decrease bottom margin for better use of space on mobile */
    }
  }
  