@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.CustomerDashboard {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "CustomerList CustomerDetails";
}
.CustomerDashboard .CustomerList {
  width: 80%;
  margin: 5px auto;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.CustomerDashboard .CustomerList .controls {
  display: grid;
  /*  grid-template-columns: 1fr .2fr;
    button.refresh {
        color: red;
      }*/
}
.CustomerDashboard .CustomerList .Search_bar {
  border: none;
  width: 80%;
  border-radius: 0px;
  text-align: center;
  margin: 10px auto;
  display: grid;
  border-bottom: 2px solid #0a6887;
}
.CustomerDashboard .CustomerList::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.CustomerDashboard .CustomerList::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

.modal-overlay {
  display: grid;
  place-content: center;
}
.modal-overlay .modal {
  display: grid;
  margin: 0 auto;
  background-color: white;
  width: 90vw;
  height: 90vh;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 50px 1fr 250px;
  grid-template-areas: "header_title ADdPaymentinfo" "Selected_invoices ADdPaymentinfo" "chequelist ADdPaymentinfo";
}
.modal-overlay .modal .header_title {
  grid-area: header_title;
  display: grid;
  border-bottom: 3px solid #0a6887;
  text-align: center;
}
.modal-overlay .modal .Selected_invoices {
  grid-area: Selected_invoices;
  width: 100%;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: scroll;
}
.modal-overlay .modal .chequelist {
  grid-template-areas: chequelist;
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow-x: auto;
  overflow-x: scroll;
  scrollbar-width: none; /* Hide the default scrollbar */
  -ms-overflow-style: none;
}
.modal-overlay .modal .chequelist:-webkit-scrollbar {
  width: 0; /* Hide the scrollbar for Chrome, Safari, and Opera */
}
.modal-overlay .modal .chequelist .cheque {
  flex-shrink: 0;
  height: 90%;
  width: 250px;
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
  margin: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "number bank" "number bank" "amount date" " state remove-icon";
}
.modal-overlay .modal .chequelist .cheque .number {
  grid-area: number;
}
.modal-overlay .modal .chequelist .cheque .bank {
  grid-area: bank;
}
.modal-overlay .modal .chequelist .cheque .amount {
  grid-area: amount;
}
.modal-overlay .modal .chequelist .cheque .date {
  grid-area: date;
}
.modal-overlay .modal .chequelist .cheque .state {
  grid-area: state;
}
.modal-overlay .modal .chequelist .cheque .remove-icon {
  grid-area: remove-icon;
}
.modal-overlay .modal .ADdPaymentinfo {
  width: 100%;
  height: 100%;
  grid-area: ADdPaymentinfo;
  border-left: 3px solid #0a6887;
  padding: 15px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "Base" "Cash" "Cheque";
}
.modal-overlay .modal .ADdPaymentinfo input {
  border: none;
  border-bottom: 1px solid #0a6887;
  border-radius: 0;
  padding: 5px;
  margin: 5px;
}
.modal-overlay .modal .ADdPaymentinfo .Base {
  grid-area: Base;
}
.modal-overlay .modal .ADdPaymentinfo .Cash {
  grid-area: Cash;
}
.modal-overlay .modal .ADdPaymentinfo .Cheque {
  grid-area: Cheque;
}

.cheque_form {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "title title" "cheque_input_Number cheque_input_bank" "cheque_input_amount cheque_input_date" "cheque_input_state add_cheque_button";
  padding: 0;
}
.cheque_form .title_cheque_form {
  border-bottom: 3px solid #0a6887;
  grid-area: title;
  text-align: center;
  font-weight: 600;
  color: rgb(0, 0, 0);
  display: grid;
  place-content: center;
}
.cheque_form input {
  border: none;
  border-bottom: 1px solid #0a6887;
  border-radius: 0;
  padding: 5px;
  margin: 5px;
}
.cheque_form .cheque_input_Number {
  grid-area: cheque_input_Number;
}
.cheque_form .cheque_input_bank {
  grid-area: cheque_input_bank;
}
.cheque_form .cheque_input_amount {
  grid-area: cheque_input_amount;
}
.cheque_form .cheque_input_date {
  grid-area: cheque_input_date;
}
.cheque_form .cheque_input_state {
  grid-area: cheque_input_state;
}
.cheque_form .add_cheque_button {
  grid-area: add_cheque_button;
}

.Payemtns_wrapper .header {
  display: grid;
  background-color: aqua;
}

.customerinformation .customer_data {
  border-radius: 5px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "customer_Info customer_Info" "customer_contact Customer_Accounts" "customer_Location Customer_Sales_Rep";
}
.customerinformation .customer_data .card {
  border-radius: 15px;
  padding: 15px;
}
.customerinformation .customer_data .customer_Info {
  grid-area: customer_Info;
}
.customerinformation .customer_data .customer_contact {
  border: 1px solid #0a6887;
  grid-area: customer_contact;
}
.customerinformation .customer_data .customer_Location {
  border: 1px solid #0a6887;
  grid-area: customer_Location;
}
.customerinformation .customer_data .Customer_Accounts {
  border: 1px solid #0a6887;
  grid-area: Customer_Accounts;
}
.customerinformation .customer_data .Customer_Sales_Rep {
  border: 1px solid #0a6887;
  grid-area: Customer_Sales_Rep;
}
.customerinformation .customer_data .customer_Info {
  grid-area: customer_Info;
}
.customerinformation .customer_data .customer_Info table {
  border-collapse: collapse;
  width: 100%;
}
.customerinformation .customer_data .customer_Info th, .customerinformation .customer_data .customer_Info td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.customerinformation .customer_data .customer_Info th {
  background-color: #f2f2f2;
}
.customerinformation .customer_data .customer_accoutns {
  grid-area: customer_accoutns;
}
.customerinformation .customer_data .customer_accoutns table {
  border-collapse: collapse;
  width: 100%;
}
.customerinformation .customer_data .customer_accoutns th, .customerinformation .customer_data .customer_accoutns td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.customerinformation .customer_data .customer_accoutns th {
  background-color: #f2f2f2;
}

@media (max-width: 600px) {
  /* Styles for small screens go here */
  /* .CustomerDashboard{
       display: grid;
       grid-template-columns: 1fr ;
       grid-template-areas: 1fr 1fr;
       grid-template-areas: 
       "CustomerList "
       "CustomerDetails"
       ;

       .CustomerList{
           grid-area: CustomerList;
           max-height: 300px;
           width: 95%;

           .CustomerList_Item_Wrapper{
               display: grid;
               grid-auto-flow: column;
               grid-auto-columns: minmax(200px, 1fr);
               gap: 10px; /* Optional: Add space between items */
  /*           overflow-x: auto;
          }
          .CustomerList_Item_Wrapper::-webkit-scrollbar {
          width: 0px;
            }
      }
      .CustomerDetails{
          grid-area: CustomerDetails;

      }
  }*/
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80vw;
  width: 100%;
}
.modal-content h3 {
  margin-top: 0;
}
.modal-content p {
  margin-bottom: 20px;
}
.modal-content button {
  margin-right: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
}
.modal-content button.confirm-button {
  background-color: #28a745;
  color: #fff;
}
.modal-content button.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.CustomerList_Table {
  margin: 0 auto;
  width: 90vw;
}
.CustomerList_Table .Customer_Row {
  display: grid;
  grid-template-columns: repeat(4, 350px);
  grid-template-areas: "CustomerName Town route_name route_salesrep";
  grid-gap: 0;
  font-weight: lighter;
  font-size: 14px;
  place-content: center;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
}
.CustomerList_Table .Customer_Row .CustomerName {
  grid-area: CustomerName;
  padding: 0;
}
.CustomerList_Table .Customer_Row .Town {
  grid-area: Town;
  padding: 0;
  margin: 0;
}
.CustomerList_Table .Customer_Row .route_name {
  grid-area: route_name;
  padding: 0;
  margin: 0;
}
.CustomerList_Table .Customer_Row .route_salesrep {
  padding: 0;
  margin: 0;
  grid-area: route_salesrep;
}
.CustomerList_Table .Customer_Row a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.CustomerList_Table .Customer_Row:hover {
  background-color: rgba(11, 84, 148, 0.4235294118);
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.CustomerList_Table .Customer_Row:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.CustomerList_Table .Customer_Row.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
}/*# sourceMappingURL=Style.css.map */