@import "../../styles/variables";

.CustomerDashboard{
    display: grid;
    grid-template-columns: 1fr 3fr;

    grid-template-areas: 
    "CustomerList CustomerDetails"
    ;

    .CustomerList{
        .controls{
            display: grid;
          /*  grid-template-columns: 1fr .2fr;
            button.refresh {
                color: red;
              }*/
        }
        .Search_bar{
            border: none;
            width: 80%;
            border-radius: 0px;
            text-align: center;
            margin: 10px auto;
            display: grid;
            border-bottom: 2px solid $Primary-Theme-Green;
        }

       
        width: 80%;
        margin: 5px auto;
        height: 100vh;

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;

          }
          &::-webkit-scrollbar-thumb {
            border-radius: 0px;
          }
          
        overflow-y: scroll;
        overflow-x: hidden;
      
    }

   
   
}




.modal-overlay {
   //background-color: rgb(208, 127, 255);
   display: grid;
   place-content: center;

    .modal{
        display: grid;
    margin: 0 auto;        
        background-color: white;
        width: 90vw;
        height: 90vh;
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: 50px 1fr 250px;
        grid-template-areas: 
        "header_title ADdPaymentinfo"
        "Selected_invoices ADdPaymentinfo"
        "chequelist ADdPaymentinfo"
        ;

        .header_title{
            grid-area: header_title;
            display: grid;
            border-bottom: 3px solid #0a6887;

           // background-color: #2835a733;
        text-align: center;
        }
        .Selected_invoices{
            //background-color: #28a74633;
            grid-area: Selected_invoices;
            width: 100%;
            margin: 0 auto;
            max-height: 100% ;
            overflow-y: scroll;
        }

    .chequelist{
      //  background-color: #a7282833;

        grid-template-areas: chequelist;
        width:100%;
        margin:  0 auto;
        display: flex;
        overflow-x: auto;
        overflow-x: scroll;
        scrollbar-width: none; /* Hide the default scrollbar */
        -ms-overflow-style: none;

        &:-webkit-scrollbar {
            width: 0; /* Hide the scrollbar for Chrome, Safari, and Opera */
          }
          
        .cheque{
            flex-shrink: 0; 
            height: 90%;
            width: 250px;
            background-color: rgb(241, 241, 241);
            border-radius: 5px;
            margin: 5px;
           // background-color: #28a76833;

            display: grid;

            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr ;
            grid-template-areas: 
            "number bank"
            "number bank"
            "amount date"
            " state remove-icon"
            ;
            .number{
                grid-area: number;
            }
            .bank{
                grid-area: bank;
            }
            .amount{
                grid-area: amount;
            }
            .date{
                grid-area: date;
            }
            .state{
                grid-area: state;
            }
            .remove-icon{
                grid-area: remove-icon;
            }

        }
    }

        .ADdPaymentinfo{
          //  background-color: #28a74650;
            width: 100%;
            height: 100%;
            .base{
               // background-color: #0a68873b;
            }
            input{

                border: none;
                border-bottom: 1px solid $Primary-Theme-Green;
                border-radius: 0;
                padding: 5px;
                margin: 5px;
                
            }
            grid-area: ADdPaymentinfo;

            border-left: 3px solid #0a6887;
            padding: 15px;
          //  background-color: rgb(255, 188, 188);
         //   box-shadow: $shadow;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-areas: 
            "Base"
            "Cash"
            "Cheque"
            ;
                .Base{
                    grid-area: Base;
                }
                .Cash{
                    grid-area: Cash;
                }
            
            .Cheque{
                grid-area: Cheque;
            }
            
            }
    }


}

.cheque_form{

box-shadow: $shadow;
    display: grid;
border-radius: 5px;
grid-template-columns: 1fr 1fr;
grid-template-rows: 1fr 1fr 1fr ;
grid-template-areas: 
"title title"
"cheque_input_Number cheque_input_bank"
"cheque_input_amount cheque_input_date"
"cheque_input_state add_cheque_button"

;

.title_cheque_form{
    border-bottom: 3px solid #0a6887;
    grid-area:  title;
    text-align: center;
   font-weight:600;
color: rgb(0, 0, 0);
display: grid;
place-content: center;
//background-color: $Primary-Theme-Green;
}
padding: 0;
input{

    border: none;
    border-bottom: 1px solid $Primary-Theme-Green;
    border-radius: 0;
    padding: 5px;
    margin: 5px;

}
.cheque_input_Number{
        grid-area: cheque_input_Number;
    }
    .cheque_input_bank{
        grid-area: cheque_input_bank;
    }
    .cheque_input_amount{
        grid-area: cheque_input_amount;
    }
    .cheque_input_date{
        grid-area: cheque_input_date;
    }
    .cheque_input_state{
        grid-area: cheque_input_state;
    }
    .add_cheque_button{
        grid-area: add_cheque_button;
    }
}




.Payemtns_wrapper{
    .header{
        display: grid;
        background-color: aqua;
    }
}

.customerinformation{

    .customer_data{

        border-radius: 5px;
        display: grid;
grid-gap: 15px;
        grid-template-columns: 1fr 1fr;
grid-template-rows: auto auto auto;

        grid-template-areas:
        "customer_Info customer_Info" 
        "customer_contact Customer_Accounts" 
        "customer_Location Customer_Sales_Rep" 
        ;

      //  padding: 15px;
.card{
    border-radius: 15px;
    padding: 15px;
}
.customer_Info{
    grid-area: customer_Info;
    
}
.customer_contact{
    border: 1px solid  #0a6887;
    grid-area: customer_contact;
}
.customer_Location{
    border: 1px solid  #0a6887;
    grid-area: customer_Location;
}
.Customer_Accounts{
    border: 1px solid  #0a6887;
    grid-area: Customer_Accounts;
}
.Customer_Sales_Rep{
    border: 1px solid  #0a6887;
    grid-area: Customer_Sales_Rep;
}

        .customer_Info{
            grid-area: customer_Info;

  
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                padding: 8px;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }

            th {
                background-color: #f2f2f2;
            }
        }

        .customer_accoutns{
            grid-area: customer_accoutns;



                table {
                border-collapse: collapse;
                width: 100%;
                }

                th, td {
                padding: 8px;
                text-align: left;
                border-bottom: 1px solid #ddd;
                }

                th {
                background-color: #f2f2f2;
                }
}
    }
}

        //media query for msall screen 
        @media (max-width: 600px) {
            /* Styles for small screens go here */
           /* .CustomerDashboard{
                display: grid;
                grid-template-columns: 1fr ;
                grid-template-areas: 1fr 1fr;
                grid-template-areas: 
                "CustomerList "
                "CustomerDetails"
                ;
               
                .CustomerList{
                    grid-area: CustomerList;
                    max-height: 300px;
                    width: 95%;
             
                    .CustomerList_Item_Wrapper{
                        display: grid;
                        grid-auto-flow: column;
                        grid-auto-columns: minmax(200px, 1fr);
                        gap: 10px; /* Optional: Add space between items */
             /*           overflow-x: auto;
                    }
                    .CustomerList_Item_Wrapper::-webkit-scrollbar {
                    width: 0px;
                      }
                }
                .CustomerDetails{
                    grid-area: CustomerDetails;
              
                }
            }*/
          }  





          .modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .modal-content {
            background-color: #fff;
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          max-width: 80vw;
            width: 100%;
          
            h3 {
              margin-top: 0;
            }
          
            p {
              margin-bottom: 20px;
            }
          
            button {
              margin-right: 10px;
              padding: 8px 16px;
              border-radius: 4px;
              font-size: 14px;
          
              &.confirm-button {
                background-color: #28a745;
                color: #fff;
              }
          
              &.cancel-button {
                background-color: #dc3545;
                color: #fff;
              }
            }
          }
          


          .CustomerList_Table{
            margin: 0 auto;
          
          width: 90vw;
            .Customer_Row{
               // background-color: #dc3545;
               display: grid;
              //grid-template-columns: repeat(auto-fill, 350px); /* set a fixed width of 200px for each column */
              grid-template-columns: repeat(4, 350px);
              grid-template-areas: 
"CustomerName Town route_name route_salesrep"
;  

grid-gap: 0;
.CustomerName{
    grid-area: CustomerName;
    padding: 0;
}
.Town{
    grid-area: Town;
    padding: 0;
    margin: 0;
}
.route_name{
    grid-area: route_name;
    padding: 0;
    margin: 0;
}
.route_salesrep{
    padding: 0;
    margin: 0;
grid-area: route_salesrep;
}

a{
    color: black;
      font-weight: lighter;
      text-decoration: none;
    }

    font-weight: lighter;
font-size: 14px;
place-content: center;
              margin: 0 auto;
                width: 100%;
                border-radius: 5px;
               // box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
               padding: 5px 0;

               z-index: 10;
transition: 1s ease-in;
border: .5px solid #0a688727;

                &:hover{
                    background-color: #0b54946c;

                    background-color: #0b549425;
                    box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
                
                    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
                    border: 1px solid #0a688727;
                
                    z-index: 100;
                transition: .25s ease-out;
                
                scale: 1.01;

                &.Title{
                    background-color: #0a6887;
                    border-radius: 5px;
                    color: white;
                    scale: 1;
                    transition: none ;
            
            }
                  }
                &.Title{
                    background-color: #0a6887;
                    border-radius: 5px;
                    color: white;

                    
}
            }
          }

        