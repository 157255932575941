
.wheel {
	--x: 0px;
	--y: 0px;
	position: absolute;
	top: var(--y);
	left: var(--x);
	width: 500px;
	height: 500px;
	transform: translate(-50%, -50%);
	transform-origin: 0% 0%;

	&.on {
		.arc {
			opacity: 0.8;
						transform: scale(1) rotate(var(--rotation)) !important;
			transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
		}
	}

	.arc {
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 50%;
		transform-origin: 0% 100%;
		background-image: radial-gradient(circle at 0% 100%, transparent, transparent 29.5%, var(--color-border) 30%, var(--color-border) 30.5%, var(--color) 31%, var(--color) 50%, var(--color-border) 50.25%, var(--color-border) 51.5%, transparent 51.75%, transparent);
	
		transition-property: transform, opacity;
		transition-duration: 0.3s;
		transition-timing-function: cubic-bezier(0.4, -0.4, 0.7, -0.3);
		-webkit-clip-path: polygon(0 0, 0 99%, 99% 0);
		clip-path: polygon(0 0, 0 99%, 99% 0);
		opacity: 0;
		transform: scale(0) rotate(var(--rotation));

		i {
			position: absolute;
			top: 40%;
			left: 15%;
			font-size: 2rem;
			transform: rotate(calc(var(--rotation) * -1));	
			color: rgba(255, 255, 255, 0.8);
			transition: color 0.3s;
		}
	}

	@for $i from 1 through 8 {
		&[data-chosen='#{$i}'] {
			.arc {
				&:nth-child(#{$i}) {
					opacity: 1;
					transform: scale(1.1) rotate(var(--rotation)) !important;
					filter: brightness(150%);
					i {
						color: rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
		
		.arc {
			&:nth-child(#{$i}) {
				--rotation: #{-22.5 + ($i - 1) * 45}deg;
				//--color: hsl(#{($i - 1) * 45}deg, 36%, 60%);
				//--color-border: hsl(#{($i - 1) * 45}deg, 36%, 40%);
				
				
				--color: #0a6887;
				--color-border: #0a6887;
				transition-delay: #{($i % 2) * 0.015}s;
			}
		}
	}
}