.cheque-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
}
.cheque-table th,
.cheque-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.cheque-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.cheque-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.cheque-table tbody tr.Realised {
  background-color: rgba(157, 255, 157, 0.2470588235);
  border-bottom: rgb(0, 212, 0) 3px solid;
}
.cheque-table tbody tr.Bounced {
  background-color: rgba(255, 157, 157, 0.2470588235);
  border-bottom: rgb(255, 0, 0) 3px solid;
}/*# sourceMappingURL=Style.css.map */