@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.header {
  /*  display: grid;
    grid-template-columns: 1fr 1fr  1fr ;
    grid-template-rows: minmax(15px, 1fr) minmax(15px, 1fr) ;
    grid-template-areas: 
    "main main main"
    "search_ search_ search_"
    ;*/
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  position: sticky;
  top: 0;
  display: grid;
  z-index: 100;
}
@media screen and (max-width: 480px) {
  .header {
    padding-right: 25px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .header {
    padding-right: 35px;
  }
}
@media screen and (min-width: 1366px) {
  .header {
    padding-right: 50px;
  }
}
.header-logo {
  -o-object-fit: contain;
     object-fit: contain;
  width: 75px;
}
.header .HeaderWrapper {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(150px, 5fr) minmax(150px, 1fr);
  grid-template-areas: "logo  search_head  navheader";
}
.header .HeaderWrapper .search_head {
  display: grid;
  grid-area: search_head;
  width: 100%;
}
.header .HeaderWrapper .navheader {
  display: grid;
  justify-content: end;
  grid-area: navheader;
}
.header .HeaderWrapper .logo {
  grid-area: logo;
  display: grid;
  justify-content: start;
}
.header .HeaderWrapper .Header_Cart {
  grid-area: Header_Cart;
}

.RightNav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
}
.RightNav .CartCount {
  background-color: red;
  color: white;
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 50%;
  top: -0.5rem;
  right: -0.5rem;
}

.header {
  /* your existing header styles */
}

/* .sticky {
  position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 100;
  /*  additional styles for sticky header */
@media screen and (max-width: 768px) {
  .HeaderWrapper {
    display: grid;
  }
  .HeaderWrapper .search_header_wrapper {
    display: grid;
    place-content: center;
  }
}/*# sourceMappingURL=style.css.map */