.ledger_View_Table {
  width: 100%;
  display: grid;
  margin: 0 auto;
  font-size: 12px;
}
.ledger_View_Table .Ledger_View_Table_Row {
  display: grid;
  width: 100%;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 12px;
  place-content: center;
  font-weight: lighter;
  grid-template-columns: repeat(auto-fit, 125px); /* set a fixed width of 200px for each column */
  grid-template-areas: "Type voucher_Number Description Debit Credit Current_bal Delete";
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
}
.ledger_View_Table .Ledger_View_Table_Row .Type {
  grid-area: Type;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .voucher_Number {
  grid-area: voucher_Number;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Description {
  grid-area: Description;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Debit {
  grid-area: Debit;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Credit {
  grid-area: Credit;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Current_bal {
  grid-area: Current_bal;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Delete {
  grid-area: Delete;
  display: grid;
}
.ledger_View_Table .Ledger_View_Table_Row .Delete .cta {
  color: red;
}
.ledger_View_Table .Ledger_View_Table_Row.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
  font-weight: lighter;
}
.ledger_View_Table .Ledger_View_Table_Row:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.ledger_View_Table .Ledger_View_Table_Row:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}

/* Apply minimal styling to text inputs */
.input_Type {
  border: none;
  border-bottom: 1px solid #ccc; /* Add a light gray border */
  padding: 8px; /* Add some padding for spacing */
  font-size: 16px; /* Set the font size */
  border-radius: 4px; /* Add rounded corners */
}

/* Optional: Add focus styles for better user experience */
.input_Type:focus {
  outline: none; /* Remove the default outline */
  border-bottom: 1px solid #007bff; /* Change the border color on focus (blue in this example) */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle box shadow on focus */
}

.Transaction_Row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "Left right";
}
.Transaction_Row .Left {
  grid-area: Left;
  display: grid;
}
.Transaction_Row .right {
  display: grid;
  grid-area: right;
}

.runningbal {
  color: grey;
  font-size: 10px;
}

.transactions {
  width: 100%;
}
.transactions .card {
  margin: 10px auto;
  width: 80%;
  border-radius: 15px;
  max-height: 30px;
  height: 30px;
  padding: 15px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "account amount", "DateTime .";
}
.transactions .card.Outbound {
  border: 1px solid red;
}
.transactions .card.Inbound {
  border: 1px solid green;
}
.transactions .card .DateTime {
  font-size: 10px;
}
.transactions .card .reference {
  font-size: 10px;
}/*# sourceMappingURL=Style.css.map */