
.print_invoice{
width:100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "letterhead"
    "Information"
    "items"
    "footer";
    margin: 0 auto;
padding: 10px;
&.hidden{
    display: none;
}
    .letterhead {
        grid-area: letterhead;
        width: 100%;
        margin: 0 auto;
        
    }
    .Information{
        margin: 0 auto;
        width: 100%;


        grid-area: Information;
        grid-template-columns: 1fr 1fr 1fr;
        //background-color: rgba(0, 255, 255, 0.329);
font-size: 8px;
display: grid;
        grid-template-areas: 
        
        "customerinfo paymentinfo  orderinfo";
        .paymentinfo{
            grid-area: paymentinfo;
        }
        .customerinfo{
            grid-area: customerinfo;
        }
        .orderinfo{
            grid-area: orderinfo;
        }



    }
    .items{
        grid-area: items;
    }
    .footer_invoice{
        grid-area: footer;
        width: 100%;
        bottom: 0;
       // background-color: aqua;
        .signs_footer{
            width: 100%;
            .signs_invoice{
                text-align: center;
             
            }
         //   background-color: antiquewhite;
        }
    }

}





/*
.Invoice_Items{

    &._print{
    
        .Invoice_Items{
            width: 90%;
            margin: 0 auto;
            font-size: 8px;
            padding: 0;

            thead{

                tr{
                    text-align: left;
                    padding: 0;
                    th{
                    font-size:8px;
                        text-align: left;
                    padding: 0;

                    }
                }
            }
            tbody{
                tr{
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid #000;
                    td{
                    padding: 0;
                    height: 12px;
                    font-size: 8px;

                    &.subtotal{
                        text-align:right;
                    }
                    }
                   
                }
            }
            

 
        }
    }
}

*/