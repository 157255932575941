@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.Sales_rep_selector {
  grid-area: Sales_rep_selector;
  max-width: 400px;
  border-radius: 15px;
  margin: 15px auto;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid rgb(0, 207, 0);
  width: 80%;
  padding: 10px;
  background-color: rgba(0, 207, 0, 0.24);
  text-align: left;
  display: grid;
  place-content: center;
}
.Sales_rep_selector .sales_RepSelectlist {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.Sales_rep_selector .sales_RepSelectlist .sales_RepSelect {
  margin: 15px 0;
  border: 1px solid #0a6887;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #0a6887;
  cursor: pointer;
  list-style-type: none;
}
.Sales_rep_selector .sales_RepSelectlist .sales_RepSelect:hover {
  background-color: #0a6887;
  color: white;
}

.Sales_Rep_Wrapper {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}
.Sales_Rep_Wrapper a {
  text-decoration: none;
  color: black;
}
.Sales_Rep_Wrapper .Sales_rep {
  border-radius: 15px;
  border: 0.5px rgb(0, 0, 0) solid;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 50px 50px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: " Name Incomplete_Orders Urgent_order" " mail Incomplete_Orders Urgent_order";
}
.Sales_Rep_Wrapper .Sales_rep .Urgent_order {
  place-content: center;
  display: grid;
  grid-area: Urgent_order;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
  background-color: #ff0000;
}
.Sales_Rep_Wrapper .Sales_rep .Incomplete_Orders {
  place-content: center;
  display: grid;
  grid-area: Incomplete_Orders;
  background-color: #0a6887;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
}
.Sales_Rep_Wrapper .Sales_rep .mail {
  display: grid;
  grid-area: mail;
}
.Sales_Rep_Wrapper .Sales_rep .Name {
  display: grid;
  grid-area: Name;
}
.Sales_Rep_Wrapper .Sales_rep:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  transition: 0.25s ease-out;
}

.Regular_order {
  place-content: center;
  display: grid;
  grid-area: Incomplete_Orders;
  background-color: #0a6887;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
}

.Urgent_order {
  place-content: center;
  display: grid;
  grid-area: Urgent_order;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  max-width: 25px;
  max-height: 25px;
  background-color: #ff0000;
}/*# sourceMappingURL=Style.css.map */