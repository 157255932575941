.Routes_Table {
  width: 98%;
  margin: o auto;
  display: grid;
}
.Routes_Table .Routes_Table_row {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
  border-radius: 5px;
  font-size: 14px;
  place-content: center;
  font-weight: lighter;
  grid-template-areas: "Route_id Route_name Route_Action Route_Sales_rep ";
  padding: 0;
  max-height: 40px;
  margin: 0 auto;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 10;
  transition: 1s ease-in;
}
.Routes_Table .Routes_Table_row .Route_id {
  display: grid;
  grid-area: Route_id;
}
.Routes_Table .Routes_Table_row .Route_name {
  display: grid;
  grid-area: Route_name;
}
.Routes_Table .Routes_Table_row .Route_Action {
  display: grid;
  grid-area: Route_Action;
}
.Routes_Table .Routes_Table_row .Route_View {
  display: grid;
  grid-area: Route_View;
}
.Routes_Table .Routes_Table_row .Route_Sales_rep {
  display: grid;
  grid-area: Route_Sales_rep;
}
.Routes_Table .Routes_Table_row.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
}
.Routes_Table .Routes_Table_row:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.1s ease-out;
  scale: 1.01;
}
.Routes_Table .Routes_Table_row:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.Routes_Table a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}

.Town_Table {
  width: 90%;
  display: grid;
}
.Town_Table a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.Town_Table .Town_Wrapper {
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
  border-radius: 5px;
  font-size: 14px;
  place-content: center;
  font-weight: lighter;
  grid-template-areas: "Town_Id Town_Name";
  padding: 0;
  max-height: 40px;
  margin: 0 auto;
  z-index: 10;
  transition: 1s ease-in;
}
.Town_Table .Town_Wrapper .Town_Id {
  display: grid;
  grid-area: Town_Id;
}
.Town_Table .Town_Wrapper .Town_Name {
  display: grid;
  grid-area: Town_Name;
}
.Town_Table .Town_Wrapper.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
}
.Town_Table .Town_Wrapper:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.1s ease-out;
  scale: 1.01;
}
.Town_Table .Town_Wrapper:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}/*# sourceMappingURL=Style.css.map */