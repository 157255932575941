#Product_sales_trend {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
}

#Product_sales_trend th,
#Product_sales_trend td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

#Product_sales_trend th {
  background-color: #f2f2f2;
  font-weight: bold;
}

#Product_sales_trend tbody tr:hover {
  background-color: #f5f5f5;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.table th,
.table td {
  border-bottom: 1px solid #dddddd;
  text-align: left;
}

.table tr {
  max-height: 12px;
  height: 5px;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-container {
  color: red;
  /* Add any other custom styles for the container */
}
.custom-container .MuiTab-root.Mui-selected {
  color: red;
}

.SalesOrderPage .Order-tabs-container {
  display: grid;
  place-content: center;
}
.SalesOrderPage .Order-tabs-container .Order-tabs {
  border-bottom: 1px solid #ccc;
  overflow-x: auto;
  width: 100%;
}
.SalesOrderPage .Order-tabs-container .Order-tabs .Mui-selected .MuiTabIndicator-root {
  background-color: #ff0000;
}
.SalesOrderPage .Order-tabs-container .Order-tab-label {
  text-transform: capitalize;
  font-weight: 800;
}
.SalesOrderPage .Order-tabs-container .MuiTabs-indicator {
  background-color: #ff0000;
}
.SalesOrderPage .Order-tabs-container .MuiTab-root.Mui-selected {
  color: #ff0000;
}
.SalesOrderPage .tabs-container_Invoice {
  max-width: 80%; /* or whatever value you want */
}
.Order_Container_Main {
  border-radius: 15px;
  border: 1px solid black;
}

.line-product-summary-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
}

.table_Order_Product_Info {
  width: 100%;
  margin: 0 auto;
}
.table_Order_Product_Info .table_Order_Product_Info_table .table_Order_Product_Info_table_tr {
  transition: 1s ease-in;
  border: 1px solid #00bfff;
}
.table_Order_Product_Info .table_Order_Product_Info_table .table_Order_Product_Info_table_tr a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.table_Order_Product_Info .table_Order_Product_Info_table .table_Order_Product_Info_table_tr:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}

/*
  .Modal_Line_order_Summary{
    background-color: #ff0000;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-area:
    "table_order_summery notes_List"
  //  "profit profit"
    ;
    .table_order_summery{
      background-color: aqua;
      grid-area: table_order_summery;
    }

    .notes_List{
      grid-area: notes_List;
      background-color: bisque;
    }

    .profit{
      grid-area: profit;
      background-color: cadetblue;
    }
  }*//*# sourceMappingURL=Style.css.map */