@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
/*.imagerow{
    background-color: aqua;

display: grid;


.image-wrapper{
    background-color: aquamarine;
    display: grid;

        .image{
            display: grid;
            background-color: bisque;

            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            img{
            max-width:150px;

            }
        }

    }
}*/
.imagerow .image-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 15px;
}
.imagerow .image-wrapper.Arch_Mode_View {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 250px));
  grid-auto-rows: minmax(250px, 250px);
  justify-content: center; /* Center align grid items horizontally */
  overflow-y: scroll;
  max-height: 500px;
}
.imagerow .image-wrapper::-webkit-scrollbar {
  display: none;
}
.imagerow .image-wrapper .image {
  margin: 0 auto;
  flex: 0 0 auto;
}
.imagerow .image-wrapper .image img {
  border: 2px solid #359c8d;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  max-width: 50px;
}
.imagerow .image-wrapper .image img.Arch_Mode_View {
  max-width: 200px;
}

.remove-button:hover {
  background-color: #cc0000;
}

.remove-button {
  background-color: #ff1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.imagerowmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.imagerowmodal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  padding: 20px;
}

.Title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.image-index {
  font-size: 14px;
  margin-bottom: 10px;
}

.modal-image {
  text-align: center;
}

.modal-image img {
  max-width: 50vw;
  max-height: 50vh;
}

.modal-image-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.modal-image-arrows .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.modal-image-arrows .arrow svg {
  width: 20px;
  height: 20px;
}

.modal-image-arrows .left-arrow {
  margin-right: 10px;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modal-buttons .remove-button {
  border: none;
  background-color: #f44336;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-buttons .remove-button:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .imagerowmodal-content {
    max-width: 90%;
    max-height: 90%;
  }
  .imagerow .image-wrapper {
    padding: 15px;
  }
  .imagerow .image-wrapper.Arch_Mode_View {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .imagerow .image-wrapper::-webkit-scrollbar {
    display: none;
  }
  .imagerow .image-wrapper .image img {
    border: 2px solid #359c8d;
    border-radius: 5px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
    max-width: 50px;
  }
  .imagerow .image-wrapper .image img.Arch_Mode_View {
    max-width: 50px;
  }
}
@media (max-width: 480px) {
  .imagerowmodal-content {
    padding: 10px;
  }
  .Title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .image-index {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .modal-image-arrows .arrow {
    width: 30px;
    height: 30px;
  }
  .modal-image-arrows .arrow svg {
    width: 16px;
    height: 16px;
  }
  .modal-image-arrows .left-arrow {
    margin-right: 5px;
  }
  .modal-buttons {
    margin-top: 10px;
  }
  .modal-buttons .remove-button {
    padding: 8px 16px;
    font-size: 12px;
  }
}/*# sourceMappingURL=Style.css.map */