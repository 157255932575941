.Note_ui {
  display: grid;
  height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
}
.Note_ui .Note {
  font-weight: lighter;
  font-size: 14px;
  place-content: center;
  margin: 0 auto;
  width: 100%;
  border-radius: 15px;
  padding: 5px 0;
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  padding: 10px;
  margin: 5px auto;
  height: 75px;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "Message" "Info";
}
.Note_ui .Note a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.Note_ui .Note:hover {
  background-color: rgba(11, 84, 148, 0.4235294118);
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.Note_ui .Note:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.Note_ui .Note.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
}
.Note_ui .Note .Info {
  max-height: 25px;
  grid-area: Info;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "note_Creator created_Datetime";
}
.Note_ui .Note .note_Creator {
  max-height: 25px;
  font-weight: 800;
  grid-area: note_Creator;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Note_ui .Note .created_Datetime {
  max-height: 25px;
  font-weight: 800;
  grid-area: created_Datetime;
  text-align: right;
  opacity: 0.5;
}
.Note_ui .Note .Message {
  grid-area: Message;
  padding: 10px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.Note_ui .note_Creator {
  width: 150px;
  margin: 0 auto;
  font-weight: 800;
}/*# sourceMappingURL=Style.css.map */