.Admin_Dashboard {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "List data_view";
  
    .List {
      grid-area: List;
  
      .Item {
      //  background-color: rgb(19, 19, 19);
        width: 80%;
        max-width: 200px;
        margin: 5px auto;
        border-radius: 5px;
        height: 50px;
        max-height: 75px;
        display: grid;
        text-align: left;
        &._selected {
          border: 1px solid red;
        }
  
        .Item_title {
          display: grid;
          place-content: center;
        }

        



      }
    }
  
    .data_view {
      grid-area: data_view;
    //  background-color: bisque;
     //  text-align: center;
      width: 100%;
      margin: 0 auto;
    }
  }
  
  .tabs_side {
    display: grid;
  //  gap: 5px;
    margin-top: 10px;
    // text-align: left;
    width: 90%;
    max-width: 450px;
    .MuiTabs-indicator {
        background-color: #0a6887; /* Change this to your desired color */
   //  text-align: left;

      }
    .tab {

    //  background-color: rgb(252, 252, 252);
      width: 95%;
      max-width: 450px;
      margin: 0 auto;
      border-radius: 5px;
      height: 50px;
      max-height: 75px;
      display: grid;
     // place-content: center;
      cursor: pointer;
  //  text-align: left;
      font-weight: 800;
.MuiButtonBase-root{
  text-align: left;
}

      z-index: 10;
transition: 1s ease-in;
border: .5px solid #0a688727;
      &:hover{


        background-color: #0b549425;
      
        box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
        border: 1px solid #0a688727;
      
        z-index: 100;
      transition: .25s ease-out;
      
      scale: 1.01;
      color: #0a6887;
      
      }
      &._selected {
      //  border: 1px solid red;
       // color: red;
        color: #0a6887;

        box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
        border: 1px solid #0a6887;
        background-color: #0b549425;

        scale: 1.01;
        z-index: 100;

      }
    }
  }
  

._Table_Custom{
  border-spacing:0;
  width: 100%;
  max-width: 1080px;
margin: 0 auto;
  //display: grid;
  //place-content: center;
padding: 0;
  ._Table_Custom_Row{

th{
  padding: 0;
  margin: 0;
}
td{
  padding: 0;
  margin: 0;
}
    a{
      color: black;
        font-weight: lighter;
        text-decoration: none;
      }
    width: 100%;
    margin: 0 auto;
      z-index: 10;
transition: 1s ease-in;
border: none;
border: 14px solid #0a688727;
border-radius: 15px;
//display: grid;
//place-content: center;
font-weight: lighter;
font-size: 14px;
//grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* set a fixed width of 200px for each column */
&:hover{


  background-color: #0b549425;
  box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
  border: 1px solid #0a688727;

  z-index: 100;
transition: .25s ease-out;

scale: 1.01;

&.Title{
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
transition: none ;

}
}
&.Title{
  background-color: #0a6887;
border-radius: 5px;
color: white;
scale: 1;
transition: none ;
}


  }
}
  