.searchContainer {
  position: relative;
}

.searchInput {
  border: 2px solid #0a6887;
  padding-right: 30px; /* Adjust the padding to make room for the icon */
}

.clearIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: red;
}

.search_Primary {
  display: grid;
  grid-template-columns: 1fr 0.01fr 0.01fr 0.01fr 0.01fr;
  border-radius: 10px;
  border: 2px solid #0a6887;
}
.search_Primary input {
  border: none !important;
}
.search_Primary.dark {
  background-color: #333333;
  color: #fff;
}
.search_Primary .search_input {
  border: none;
}
.search_Primary .search_input.dark {
  background-color: #333333;
  color: #ffffff;
}
.search_Primary .caselock {
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  display: grid;
  place-items: center;
}
.search_Primary .caselock.highlighted {
  color: #0a6887;
}
.search_Primary .caselock.dark {
  background-color: #333333;
  color: #838383;
}
.search_Primary .caselock.dark.highlighted {
  color: #ffffff;
}
.search_Primary .search_icon {
  display: grid;
  place-items: center;
  font-weight: bold;
  font-size: 15px;
  color: #0a6887;
  cursor: pointer;
  /*   &.highlighted{
        color: #0a6887;

        }
  */
}
.search_Primary .search_icon.dark {
  background-color: #333333;
  color: #fff;
}
.search_Primary .clear_Search {
  display: grid;
  place-items: center;
  font-weight: bold;
  font-size: 15px;
  color: red;
  cursor: pointer;
}
.search_Primary .clear_Search.highlighted {
  color: #0a6887;
}
.search_Primary .clear_Search.dark {
  background-color: #333333;
  color: #fff;
}
.search_Primary .Group_filter {
  display: grid;
  place-items: center;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}
.search_Primary .Group_filter.highlighted {
  color: #0a6887;
}
.search_Primary .Group_filter.highlighted.dark {
  background-color: #333333;
  color: #838383;
}
.search_Primary .Group_filter.highlighted.dark.highlighted {
  color: #ffffff;
}

.Search_results {
  max-height: 50vh;
  z-index: 100000;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.459);
  background-color: #ffffff;
  position: absolute;
  max-width: inherit;
  /* top: 100%; Position below the parent */
  /*left: 0; Align with the left edge of the parent */
  /*margin:  0 auto;
     Match the width of the parent */
  /* width: 80%;
    left: 50%; Align with the left edge of the parent */
}/*# sourceMappingURL=Style.css.map */