.Home_Container {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  transition: max-height 0.3s ease-in-out;
}

.products_dash {
  margin: 0 auto;
}

.home-tab-label {
  margin: 10px 0;
}

.btn_area .home_menu_Button {
  display: grid;
  margin: 0 auto;
}/*# sourceMappingURL=Style_Home.css.map */