@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html {
  scroll-behavior: smooth;
  font-family: "Poppins";
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }
}
html.noscroll {
  overflow: hidden;
}

body {
  margin: 0 auto;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: grid;
  position: relative;
  background-color: hsl(0, 0%, 100%);
}

.content-child {
  animation: page-change 0.5s ease-in-out forwards;
}

iframe {
  outline: none;
  border: none;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

p {
  line-height: 130%;
  margin: 0;
  margin-block: 0;
  font-family: "Poppins";
}
p.danger {
  color: rgb(248, 81, 73);
}

strong.danger {
  color: rgb(248, 81, 73);
}

label {
  font-family: "Poppins";
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  label {
    font-size: 14px;
  }
}

h1, h2, h3, h4 {
  font-family: "Poppins";
  margin: 0;
  font-weight: normal;
}

h1 {
  font-family: "Poppins";
  font-weight: 700;
  margin: 0;
  margin-block: 0;
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 34px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  h1 {
    font-size: 40px;
  }
}
@media screen and (min-width: 1366px) {
  h1 {
    font-size: 44px;
  }
}

h2 {
  font-family: "Poppins";
  font-weight: 600;
}
@media screen and (max-width: 480px) {
  h2 {
    font-size: 23px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1366px) {
  h2 {
    font-size: 32px;
  }
}

h3 {
  font-family: "Poppins";
  font-weight: 500;
}
@media screen and (max-width: 480px) {
  h3 {
    font-size: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  h3 {
    font-size: 22px;
  }
}
@media screen and (min-width: 1366px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-family: "Poppins";
  font-weight: 500;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  font-family: "Poppins";
}

input {
  font-family: "Poppins";
}
input[type=button], input[type=submit] {
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

select,
input,
textarea,
.search-select > div {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #9A9A9A;
  transition: 0.1s ease-in-out;
  margin: 0;
}
@media screen and (max-width: 480px) {
  select,
  input,
  textarea,
  .search-select > div {
    font-size: 14px;
  }
}
select:disabled,
input:disabled,
textarea:disabled,
.search-select > div:disabled {
  cursor: not-allowed;
  opacity: 1;
  background-color: rgb(245, 245, 245);
  border-color: rgb(245, 245, 245);
  color: #2b2b2b;
}
select::-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder, .search-select > div::-moz-placeholder {
  color: #9A9A9A;
}
select::placeholder,
input::placeholder,
textarea::placeholder,
.search-select > div::placeholder {
  color: #9A9A9A;
}
select input:not([type=submit]):hover:not(:disabled), select input:not([type=submit]):focus:not(:disabled),
select input:not([type=button]):hover:not(:disabled),
select input:not([type=button]):focus:not(:disabled),
input input:not([type=submit]):hover:not(:disabled),
input input:not([type=submit]):focus:not(:disabled),
input input:not([type=button]):hover:not(:disabled),
input input:not([type=button]):focus:not(:disabled),
textarea input:not([type=submit]):hover:not(:disabled),
textarea input:not([type=submit]):focus:not(:disabled),
textarea input:not([type=button]):hover:not(:disabled),
textarea input:not([type=button]):focus:not(:disabled),
.search-select > div input:not([type=submit]):hover:not(:disabled),
.search-select > div input:not([type=submit]):focus:not(:disabled),
.search-select > div input:not([type=button]):hover:not(:disabled),
.search-select > div input:not([type=button]):focus:not(:disabled) {
  border-color: #0a6887;
}
select option,
input option,
textarea option,
.search-select > div option {
  font-family: "Poppins";
}

.search-select > div {
  padding: 2.5px 5px;
}

button,
input[type=submit],
input[type=button] {
  cursor: pointer;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  max-height: 45px;
  background-color: white;
  color: #2b2b2b;
  border: 1px solid #2b2b2b;
  transition: all 0.1s ease-in-out;
}
@media screen and (max-width: 480px) {
  button,
  input[type=submit],
  input[type=button] {
    font-size: 14px;
  }
}
button:disabled,
input[type=submit]:disabled,
input[type=button]:disabled {
  cursor: not-allowed;
  border: 1px solid #9A9A9A !important;
  background-color: #9A9A9A !important;
  color: white !important;
}
button:hover,
input[type=submit]:hover,
input[type=button]:hover {
  opacity: 90%;
}
button svg,
input[type=submit] svg,
input[type=button] svg {
  display: inline;
}

a {
  text-decoration: none;
  font-family: "Poppins";
}
a:disabled {
  cursor: not-allowed;
}

button {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  border-radius: 20px;
  font-family: "Poppins";
}
button:disabled {
  cursor: not-allowed;
}

button {
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

svg {
  display: block;
}

table {
  font-family: "Poppins";
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  table {
    font-size: 14px;
  }
}
table th {
  text-align: start;
  font-weight: 500;
  line-height: 100%;
  font-size: 17px;
}
@media screen and (max-width: 480px) {
  table th {
    font-size: 15px;
  }
}

.inline-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 30px;
       column-gap: 30px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .inline-inputs {
    grid-template-columns: repeat(1, 1fr);
  }
}

.labeled-input {
  position: relative;
}
.labeled-input select,
.labeled-input input,
.labeled-input textarea {
  padding: 12.5px 10px;
  line-height: 100%;
  font-weight: normal;
  border: none;
  border-radius: 0;
  border-bottom: 3px solid #9A9A9A;
  width: 100%;
}
.labeled-input select:focus,
.labeled-input input:focus,
.labeled-input textarea:focus {
  border-bottom-color: #0a6887;
}
.labeled-input select:focus ~ label,
.labeled-input input:focus ~ label,
.labeled-input textarea:focus ~ label {
  color: #0a6887;
}
@media screen and (max-width: 480px) {
  .labeled-input select,
  .labeled-input input,
  .labeled-input textarea {
    padding: 10px 7.5px;
  }
}
.labeled-input label {
  pointer-events: none;
  width: 100%;
  color: #9A9A9A;
  font-weight: normal;
  position: absolute;
  left: 10px;
  top: 0%;
  transform: translateY(-50%);
  transition: 0.2s ease;
}
@media screen and (max-width: 480px) {
  .labeled-input label {
    left: 7.5px;
  }
}

.input {
  position: relative;
  margin-bottom: 15px;
  text-align: start;
}
.input select:not([type=checkbox]),
.input input:not([type=checkbox]),
.input textarea:not([type=checkbox]),
.input .search-select:not([type=checkbox]) {
  width: 100%;
}
.input label,
.input p {
  display: block;
  width: 100%;
  padding-left: 3px;
  font-size: 90%;
  font-weight: 500;
  color: #2b2b2b;
}
.input p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2.5px;
}
@media screen and (max-width: 480px) {
  .input p {
    font-size: 12px;
  }
}
.input.image-edit-input img {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (min-width: 1366px) {
  .input.image-edit-input img {
    width: 50%;
  }
}

.floating-input {
  position: relative;
  height: -moz-max-content;
  height: max-content;
  margin-bottom: 15px;
}
.floating-input select,
.floating-input input {
  padding: 12.5px 10px;
  line-height: 100%;
  font-weight: normal;
  border: none;
  border-radius: 0;
  border: 1px solid #9A9A9A;
  border-radius: 10px;
  width: 100%;
}
.floating-input select::-moz-placeholder, .floating-input input::-moz-placeholder {
  visibility: hidden;
}
.floating-input select::placeholder,
.floating-input input::placeholder {
  visibility: hidden;
}
.floating-input select:focus,
.floating-input input:focus {
  border-color: #0a6887;
}
.floating-input select:focus::-moz-placeholder, .floating-input input:focus::-moz-placeholder {
  visibility: visible;
}
.floating-input select:focus::placeholder,
.floating-input input:focus::placeholder {
  visibility: visible;
}
.floating-input select:focus ~ label,
.floating-input input:focus ~ label {
  color: #0a6887;
  font-size: 90%;
  top: 0%;
}
@media screen and (max-width: 480px) {
  .floating-input select,
  .floating-input input {
    padding: 10px 7.5px;
  }
}
.floating-input input:not(:-moz-placeholder-shown) {
  border-color: #0a6887;
}
.floating-input select:not([value=""]):valid,
.floating-input input:not(:placeholder-shown) {
  border-color: #0a6887;
}
.floating-input input:not(:-moz-placeholder-shown) ~ label {
  color: #0a6887;
  font-size: 90%;
  top: 0%;
}
.floating-input select:not([value=""]):valid ~ label,
.floating-input input:not(:placeholder-shown) ~ label {
  color: #0a6887;
  font-size: 90%;
  top: 0%;
}
.floating-input label {
  pointer-events: none;
  background-color: white;
  color: #9A9A9A;
  font-weight: normal;
  line-height: 100%;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease;
  width: -moz-max-content;
  width: max-content;
  padding: 0 7.5px;
}
@media screen and (max-width: 480px) {
  .floating-input label {
    left: 10px;
  }
}

.radio-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.radio-input input {
  margin-right: 5px;
}

.inline-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .inline-btns {
    flex-direction: column;
  }
}
.inline-btns button,
.inline-btns input[type=submit],
.inline-btns input[type=button] {
  border-radius: 15px;
  font-weight: 500;
  line-height: 100%;
  transition: 0.3s ease-in-out;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .inline-btns button,
  .inline-btns input[type=submit],
  .inline-btns input[type=button] {
    width: 100%;
    font-size: 16px;
  }
}
@media screen and (min-width: 481px) {
  .inline-btns button,
  .inline-btns input[type=submit],
  .inline-btns input[type=button] {
    width: 50%;
  }
  .inline-btns button:not(:last-of-type),
  .inline-btns input[type=submit]:not(:last-of-type),
  .inline-btns input[type=button]:not(:last-of-type) {
    margin-right: 10px;
  }
}

.stacked-btns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .stacked-btns {
    flex-direction: column;
  }
}
.stacked-btns button,
.stacked-btns input[type=submit],
.stacked-btns input[type=button] {
  border-radius: 15px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 480px) {
  .stacked-btns button,
  .stacked-btns input[type=submit],
  .stacked-btns input[type=button] {
    font-size: 16px;
  }
}
@media screen and (min-width: 481px) {
  .stacked-btns button:not(:last-of-type),
  .stacked-btns input[type=submit]:not(:last-of-type),
  .stacked-btns input[type=button]:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.live-search {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 10px;
}
@media screen and (max-width: 480px) {
  .live-search {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.live-search.open {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}
.live-search.open .live-search-results {
  position: absolute;
  top: 100%;
  background-color: white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0 0 10px 10px;
  border: 1px solid #0a6887;
  border-top: none;
  height: -moz-max-content;
  height: max-content;
  padding: 10px 20px !important;
}
.live-search.open .live-search-input {
  box-shadow: none !important;
}
.live-search.open .live-search-input input {
  border-radius: 10px 10px 0 0;
  border: 1px solid #0a6887;
  border-bottom: none;
  transition: none !important;
}
.live-search-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
}
.live-search-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  font-weight: 500;
  border-radius: 10px;
  color: rgba(43, 43, 43, 0.8);
  background-color: white;
  z-index: 3;
  width: 100%;
}
.live-search-input input {
  width: 100%;
  padding: 12.5px 10px;
}
@media screen and (max-width: 480px) {
  .live-search-input input {
    padding: 10px 7.5px;
  }
}
.live-search-input button {
  position: absolute;
  top: 0%;
  height: 100%;
  padding: 10px 15px;
  transition: 0.2s ease-in-out;
}
.live-search-input button:nth-of-type(1) {
  right: 0;
}
.live-search-input button:nth-of-type(1):hover svg {
  fill: rgb(248, 81, 73);
}
.live-search-input button:nth-of-type(1) svg {
  fill: #2b2b2b;
  transition: 0.2s ease-in-out;
  height: 15px;
}
.live-search-results {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  max-height: 25vh;
  overflow-y: auto;
}
@media screen and (max-width: 480px) {
  .live-search-results {
    width: 100%;
  }
}
.live-search-results-result {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
}
.live-search-results-result p {
  max-width: 75%;
}
.live-search-results-result button {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  color: #0a6887;
  font-weight: 500;
}
.live-search-results-result button svg {
  fill: #0a6887;
  height: 20px;
  width: auto;
}
.live-search-results-minimize {
  position: absolute;
  right: 20px;
  font-weight: 500;
  opacity: 80%;
  font-size: 90%;
  padding: 0;
  align-items: center;
}
.live-search-results-info {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
  margin: 25px 0 5px;
}
.live-search-results-info-heading {
  font-size: 90%;
  color: #9A9A9A;
}
.live-search-results-info-count {
  font-size: 75%;
  color: #9A9A9A;
  width: 100%;
  text-align: end;
}

.dates-picker {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  background-color: white;
  border-radius: 20px;
}
@media screen and (max-width: 480px) {
  .dates-picker {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.dates-picker.open {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}
.dates-picker.open .dates-picker-inputs {
  position: absolute;
  top: 100%;
  background-color: white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0 0 20px 20px;
  height: -moz-max-content;
  height: max-content;
  padding: 10px 20px 20px !important;
}
.dates-picker.open .dates-picker-toggle {
  box-shadow: none !important;
  border-radius: 20px 20px 0 0;
  transition: none !important;
  z-index: 3;
}
.dates-picker.open .dates-picker-toggle svg:nth-of-type(2) {
  rotate: -90deg;
}
.dates-picker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
}
.dates-picker-wrapper.reports {
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .dates-picker-wrapper.reports {
    margin-bottom: 20px;
  }
}
.dates-picker-toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  font-weight: 500;
  border-radius: 15px;
  color: rgba(43, 43, 43, 0.8);
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  z-index: 3;
}
.dates-picker-toggle svg {
  transition: 0.3s ease-in-out;
  fill: rgba(43, 43, 43, 0.8);
}
.dates-picker-toggle svg:nth-of-type(1) {
  margin-right: 10px;
}
.dates-picker-toggle svg:nth-of-type(2) {
  width: 7.5px;
  margin-left: 20px;
  rotate: 90deg;
}
@media screen and (max-width: 480px) {
  .dates-picker-toggle {
    width: 100%;
  }
}
.dates-picker-inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .dates-picker-inputs {
    width: 100%;
  }
}
.dates-picker-inputs fieldset {
  margin-bottom: 15px;
}
.dates-picker-inputs-pickers p {
  margin: 0 5px 2.5px;
  text-align: start;
}
.dates-picker-inputs-pickers input[type=date] {
  width: 100%;
}
.dates-picker-inputs-pickers input[type=date]:not(:last-of-type) {
  margin-bottom: 10px;
}
.dates-picker-inputs-pickers input[type=button] {
  width: 100%;
  margin-top: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  background-color: #0a6887;
  color: white;
}

.modal {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  width: 75%;
}
.modal-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
.modal-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal-top h3 {
  margin-bottom: 0 !important;
}
.modal-top button {
  position: relative !important;
  top: 0% !important;
  right: 0% !important;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
.modal-top button:hover {
  background-color: inherit !important;
}
.modal-top button svg {
  fill: #2b2b2b !important;
  transform: rotate(180deg) !important;
  height: 17.5px !important;
}

.input-search {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  width: 75%;
}
.input-search-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.input-search p {
  margin-bottom: 10px;
}
.input-search-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.input-search-top h3 {
  margin-bottom: 0 !important;
}
.input-search-top button {
  position: relative;
  top: 0%;
  right: 0%;
  padding: 0;
}
.input-search-top button svg {
  fill: #2b2b2b;
  transform: rotate(180deg);
  height: 17.5px;
}
.input-search-selectedvalue {
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 100% !important;
  margin: 5px 0 !important;
}
.input-search form {
  box-shadow: none !important;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .password-requirements {
    margin-top: 30px;
  }
}
@media screen and (min-width: 481px) {
  .password-requirements {
    margin-bottom: 25px;
  }
}
.password-requirements li {
  display: grid;
  grid-template-columns: 16px auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
  list-style: none;
  line-height: 115%;
  margin-bottom: 5px;
  color: #9A9A9A;
  transition: 0.3s ease-in-out;
}
.password-requirements li svg {
  fill: #9A9A9A;
  height: 16px;
  width: 16px;
}
.password-requirements li.failed {
  color: rgb(248, 81, 73);
}
.password-requirements li.failed svg {
  fill: rgb(248, 81, 73);
}
.password-requirements li.passed {
  color: #0a6887;
}
.password-requirements li.passed svg {
  fill: #0a6887;
}

@keyframes page-change {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
nav {
  position: relative;
}
nav .nav-user {
  position: relative;
}
nav .nav-user button {
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 12.5px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}
nav .nav-user button svg {
  height: 24px;
  transition: 0.2s ease-in-out;
  fill: #2b2b2b;
}
nav .nav-user button:hover svg {
  fill: #0a6887;
}
nav .nav-user button.open {
  background-color: white;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.158);
  transition-delay: 0s;
}
nav .nav-user button.open svg {
  fill: #0a6887;
}
nav .nav-user-dropdown {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 20px 0px 20px 20px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  width: 0;
  height: 0;
  padding: 0;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, height 0.2s ease-in-out;
  background-color: white;
}
nav .nav-user-dropdown.open {
  display: block;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 20px 25px;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out, width 0.2s ease-in-out;
}
@media screen and (max-width: 480px) {
  nav .nav-user-dropdown.open {
    width: 50vw;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  nav .nav-user-dropdown.open {
    width: 25vw;
  }
}
@media screen and (min-width: 1366px) {
  nav .nav-user-dropdown.open {
    width: 10vw;
  }
}
.nav-user button svg {
  fill: #2b2b2b;
}

nav .nav-user-dropdown a {
  display: block;
  line-height: 100%;
  color: #0a6887;
  transition: 0.2s ease-in-out;
}
nav .nav-user-dropdown a:not(:last-of-type) {
  margin-bottom: 10px;
}
nav .nav-user-dropdown a:hover {
  opacity: 90%;
}

.addtocartBtn {
  background-color: transparent;
  color: #14185B;
  border-radius: 5px;
  width: 90%;
  padding: 5px;
  max-height: 50px;
}
.addtocartBtn:hover {
  background-color: #14185B;
  color: white;
}

.image-slider.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.image-slider.empty p {
  font-size: 2rem;
  color: gray;
}
.image-slider .image-wrapper {
  position: relative;
  grid-area: image-wrapper;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.image-slider .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.image-slider .image-wrapper.zoomed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: zoom-out;
}
.image-slider .nav {
  grid-area: nav;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.image-slider .nav .nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.image-slider .nav .nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.2);
}
.image-slider .nav .prev {
  transform: rotateY(180deg);
}
.image-slider .image-selector {
  grid-area: image-selector;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.image-slider .image-selector .image-selector-button {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.image-slider .image-selector .image-selector-button:hover, .image-slider .image-selector .image-selector-button.active {
  background-color: rgba(0, 0, 0, 0.5);
}
.image-slider .image-selector .image-selector-button.active {
  transform: scale(1.2);
}
.image-slider .report-button {
  grid-area: report-button;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-size: 2rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.image-slider .report-button:hover {
  background-color: black;
  color: white;
  transform: scale(1.2);
}

.image-slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.image-slider-modal .image-slider {
  display: grid;
  grid-template-areas: "image-wrapper" "nav" "image-selector" "report-button";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto auto;
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .image-slider-modal .image-slider {
    grid-template-areas: "image-wrapper nav" "image-selector image-selector" "report-button report-button";
    grid-template-columns: 1fr 50px;
    grid-template-rows: 1fr auto auto;
    width: 90%;
    height: 90%;
  }
}
.image-slider-modal .image-slider.empty {
  grid-template-areas: "empty-message empty-message" "nav nav" "image-selector image-selector" "report-button report-button";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto auto auto;
}
.image-slider-modal .image-slider.empty .empty-message {
  grid-area: empty-message;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.image-slider-modal .image-slider.empty .empty-message p {
  font-size: 2rem;
  color: gray;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader-wrapper {
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.975);
  z-index: 1000;
}
.loader-wrapper.hidden {
  display: none;
}
.loader-wrapper.hidden svg {
  animation: none;
}
.loader-wrapper.disabled {
  opacity: 0;
}
.loader-wrapper.enabled {
  display: block;
}
.loader-wrapper svg {
  animation: spin 0.75s normal linear infinite;
}
.loader-wrapper p {
  margin-top: 20px;
  font-weight: 500;
}
.loader svg {
  fill: #0a6887;
  width: 75px;
  height: 75px;
}
@media screen and (max-width: 480px) {
  .loader svg {
    width: 50px;
    height: 50px;
  }
}
@keyframes spin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.loading-animation div {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0a6887;
  margin-right: 5px;
  outline: 2px solid white;
  animation: loading 1s ease-in-out infinite;
}

.loading-animation div:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-animation div:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-animation div:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading {
  0% {
    transform: translate(0, 0);
    opacity: 20%;
  }
  50% {
    transform: translate(0, 0);
    opacity: 80%;
  }
  75% {
    transform: translate(0, 20px);
    opacity: 100%;
  }
  100% {
    transform: translate(0, 0);
    opacity: 20%;
  }
}
.alert {
  position: fixed;
  top: -20%;
  left: 50%;
  transform: translate(-50%, 25%);
  border-radius: 30px;
  border: 1px solid #e5e5e5;
  padding: 25px 10px;
  background-color: white;
  transition: 0.3s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
@media screen and (min-width: 1366px) {
  .alert {
    width: 30%;
  }
}
.alert-wrapper {
  display: none;
}
.alert.active {
  top: 0%;
}
.alert p {
  text-align: center;
}

.BrandCard_wrapper {
  display: grid;
  margin: 0 auto;
  grid-gap: 15px;
}
.BrandCard_wrapper .BrandCard {
  display: grid;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.BrandCard_wrapper .BrandCard .ItemName {
  font-size: 16px;
  font-weight: 600;
  color: #c01f1f;
  text-align: center;
  padding: 1rem;
}
.BrandCard_wrapper .BrandCard .ProductCount {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
.BrandCard_wrapper .BrandCard .ArchCount {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .BrandCard_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .BrandCard_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
@media screen and (min-width: 1280px) {
  .BrandCard_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .BrandCard_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
@media screen and (min-width: 1600px) {
  .BrandCard_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
.productArch {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  padding: 15px;
}

.fileuploadmodal {
  grid-area: fileuploadmodal;
}

.iamgeupload {
  grid-area: iamgeupload;
}
.iamgeupload button {
  font-size: 9px;
}

.title {
  background-color: #0a6887;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: 600;
  top: 0;
  z-index: 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 20px 1fr 1fr 1fr 1fr;
  grid-row: 1fr 1fr;
  grid-template-areas: "minimize-button titlename ArcCode OEM_Code iamgeupload" "fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal";
}
.title .minimize-button {
  grid-area: minimize-button;
  color: white;
  text-align: left;
  display: grid;
  place-content: center;
}
.title .ArcCode {
  grid-area: ArcCode;
}
.title .OEM_Code {
  grid-area: OEM_Code;
}
.title .iamgeupload button {
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.products {
  display: grid;
}

.product {
  width: 90%;
  display: grid;
  align-items: center;
  margin: 0 auto;
  grid-template-rows: 1fr 1fr minmax(1fr, 100px) auto 1fr;
  grid-template-columns: 1fr 1fr;
  font-size: 10px;
  text-align: center;
  grid-template-areas: "productname productname" "suppliercode Acode" "Model brand_Name" "Unit_Price quantity" "Sensitive_Info Sensitive_Info" "addtocart addtocart";
}
.product .suppliercode {
  text-align: left;
  grid-area: suppliercode;
}
.product .productname {
  grid-area: productname;
  display: flex;
  align-items: center;
  text-align: left;
}
.product .Acode {
  grid-area: Acode;
  text-align: right;
}
.product .Model {
  grid-area: Model;
}
.product .brand_Name {
  display: grid;
  justify-content: end;
  text-align: right;
  grid-area: brand_Name;
}
.product .quantity {
  grid-area: quantity;
  display: grid;
  text-align: right;
}
.product .Unit_Price {
  text-align: left;
  grid-area: Unit_Price;
}
.product .Sensitive_Info {
  grid-area: Sensitive_Info;
}
.product .addtocart {
  grid-area: addtocart;
  justify-content: end;
}
.product .Flagitem {
  grid-area: Flagitem;
}
.product .BillingGroup {
  grid-area: BillingGroup;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 15px;
  }
  .product {
    font-size: 15px;
    display: grid;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, 200px); /* set a fixed width of 200px for each column */
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr; /* set a fixed width of 200px for each column */
    grid-template-rows: 1fr;
    grid-template-areas: " productname  brand_Name   quantity  Unit_Price   addtocart";
  }
  .product .Unit_Price {
    text-align: right;
    grid-area: Unit_Price;
  }
  .product .Acode {
    grid-area: Acode;
    text-align: center;
  }
}
.list {
  display: flex;
  flex-direction: column;
}

.item {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  cursor: grab;
}

.item:hover {
  background-color: #f4f4f4;
}

.item:active {
  cursor: grabbing;
  background-color: #eee;
}

.brand_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.productbrandselectlist {
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.productbrandselectlist .productbrandselect {
  margin: 15px 0;
  border: 1px solid #0a6887;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 80%;
  font-size: 12px;
  color: #0a6887;
  cursor: pointer;
  text-align: left;
  list-style-type: none;
}
.productbrandselectlist .productbrandselect:hover {
  background-color: #0a6887;
  color: white;
}

.cartContainer {
  background-color: aqua;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
@media (min-width: 768px) {
  .cartContainer {
    margin: 2rem;
  }
}
@media (min-width: 1024px) {
  .cartContainer {
    margin: 3rem;
  }
}

.tableHead {
  display: none;
}
@media (min-width: 768px) {
  .tableHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-weight: bold;
    background-color: #f2f2f2;
    margin-bottom: 1rem;
  }
}

.tableBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .tableBody {
    overflow-x: auto;
  }
}

.tableBody > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}
@media (min-width: 768px) {
  .tableBody > div {
    margin-bottom: 1rem;
  }
}

.CartPage {
  font-size: 13px;
  display: grid;
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: minmax(1fr, 250px) 1fr 1fr minmax(1fr, 250px);
  grid-template-areas: "customer Sales_rep_selector Cartemptymessage totalItemsincart" "CartItems CartItems CartItems CartItems" "CartItems CartItems CartItems CartItems" "cartbottom cartbottom cartbottom cartbottom";
}
.CartPage .totalItemsincart {
  grid-area: totalItemsincart;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  place-items: center;
  display: grid;
}
.CartPage .billing_group {
  border: 1px solid #0a6887;
  border-radius: 10px;
  margin: 15px auto;
  width: 95%;
  padding: 15px;
}
.CartPage .CartItems {
  grid-area: CartItems;
  display: grid;
  border-radius: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto);
  margin: 15px auto;
  width: 95%;
}
.CartPage .Cartemptymessage {
  grid-area: Cartemptymessage;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  place-items: center;
  display: grid;
}

.Cart_row_title_C {
  display: grid;
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
  grid-template-columns: 1fr 0.1fr;
  grid-row: 1fr;
  grid-template-areas: "Titles_Cart_Line_Item  Titles_Cart_Line_Remove";
}
.Cart_row_title_C .Titles_Cart_Line_Item {
  grid-area: Titles_Cart_Line_Item;
}
.Cart_row_title_C .Titles_Cart_Line_Remove {
  grid-area: Titles_Cart_Line_Remove;
  color: red;
  color: white;
  margin: 0 auto;
}

.CartRow {
  display: grid;
  margin: 10px 0;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 14px;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 10;
  transition: 1s ease-in;
  border: 0.5px solid rgba(10, 104, 135, 0.1529411765);
  grid-template-columns: 1fr 0.1fr;
  grid-row: 1fr;
  grid-template-areas: "cartprodcut  Remove";
}
.CartRow:hover {
  background-color: rgba(11, 84, 148, 0.4235294118);
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}
.CartRow:hover.Title {
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
  transition: none;
}
.CartRow .cartprodcut {
  grid-area: cartprodcut;
  font-weight: lighter;
}
.CartRow .Discount {
  grid-area: Discount;
}
.CartRow .Quantity {
  grid-area: Quantity;
}
.CartRow .Subtotal {
  grid-area: Subtotal;
}
.CartRow .Remove {
  grid-area: Remove;
  font-weight: 600;
  color: red;
  margin: 0 auto;
}

.cartbottom {
  width: 90%;
  margin: 0 auto;
  grid-area: cartbottom;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "clearcart Urgent placeorder carttotal";
}
.cartbottom .carttotal {
  display: grid;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
  grid-area: carttotal;
  margin: auto;
}
.cartbottom .Urgent {
  grid-area: Urgent;
}
.cartbottom .placeorder {
  grid-area: placeorder;
}
.cartbottom .clearcart {
  grid-area: clearcart;
}

.customer {
  grid-area: customer;
  max-width: 400px;
  border-radius: 15px;
  margin: 15px auto;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #0a6887;
  width: 80%;
  padding: 10px;
  background-color: #EEEFFF;
  text-align: left;
  display: grid;
  place-content: center;
}
.customer .CustomerSelectlist {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.customer .CustomerSelectlist .CustomerSelect {
  margin: 15px 0;
  border: 1px solid #0a6887;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #0a6887;
  cursor: pointer;
  list-style-type: none;
}
.customer .CustomerSelectlist .CustomerSelect:hover {
  background-color: #0a6887;
  color: white;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #043747;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #0a6887;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #0a6887;
  border-radius: 50px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
}

.button_one {
  background-color: #EEEFFF;
  color: rgb(0, 0, 0);
  border: 2px solid #14185B;
  border-radius: 5px;
  width: 90%;
  max-width: 250px;
  max-height: 50px;
  margin: 10px auto;
  padding: 15px;
}
.button_one:hover {
  background-color: #14185B;
  color: white;
}
.button_one.danger {
  background-color: rgb(248, 81, 73);
  color: white;
  border: 2px solid rgb(248, 81, 73);
}
.button_one.danger:hover {
  background-color: rgb(248, 81, 73);
  color: white;
}
.button_one.Place_invoice {
  border: 2px solid #0a6887;
  background-color: #EEEFFF;
  color: #0a6887;
}
.button_one.Place_invoice:hover {
  background-color: #0a6887;
  color: white;
}
.button_one.sendicon {
  width: 50px;
  max-width: 50px;
}

.fileuploadmodal {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 9999;
  overflow: auto;
}
.fileuploadmodal .fileuploadmodalmodal-content {
  display: grid;
  margin: auto auto;
  position: relative;
  align-items: center;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  height: 90%;
  max-height: 100VH;
  z-index: 9999;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(1fr, 150px) minmax(3fr, 300px) minmax(1fr, 150px);
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 9999;
  color: #0a6887;
  justify-content: center;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header .close-button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body {
  display: grid;
  width: 95%;
  z-index: 9999;
  margin: 0 auto;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(5fr, 300px) minmax(1fr, 150px);
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .dropzone {
  max-height: 100vh;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  color: #0a6887;
  margin: 0 auto;
  border-radius: 5px;
  width: 100%;
  height: 90%;
  border: 2px solid black;
  display: grid;
  justify-content: center;
  z-index: 1000;
  align-items: center;
  margin-bottom: 20px;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .dropzone p {
  font-size: 16px;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files {
  height: 150px;
  max-height: 150px;
  display: grid;
  overflow-y: scroll;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file {
  color: #0a6887;
  display: grid;
  padding: 10px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file .image-preview {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file .preview-image {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file p {
  margin-right: 10px;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file button {
  margin: 0 auto;
  border-radius: 0;
  border: 1px red solid;
  background-color: transparent;
  color: red;
  cursor: pointer;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button:first-child {
  background-color: #4caf50;
  color: white;
}
.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button:last-child {
  background-color: #f44336;
  color: white;
}
.fileuploadmodal .fileuploadmodalmodal-content .edit-modal {
  display: grid;
  place-content: center;
  margin: 0 auto;
  width: 80vw;
}
.fileuploadmodal .fileuploadmodalmodal-content .edit-modal .edit-modal-content {
  position: absolute;
  top: 0;
  left: 10;
  z-index: 991000;
  width: 80vw;
  height: 50vh;
  max-width: 1000px;
  max-height: 700px;
  margin-top: 350px;
  margin: 350px auto 0 auto;
}
.fileuploadmodal .fileuploadmodalmodal-content .edit-modal .edit-modal-content .responsive-image-editor {
  z-index: 991000;
  margin-top: 125px;
  /*  display: grid;
  place-content: center;*/
}

.area {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #0a6887;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.wrapper_Dashboard_Director {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "DirectorQuickAccess DirectorDashboard";
  background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 800px) {
  .wrapper_Dashboard_Director {
    grid-template-columns: 1fr;
    grid-template-areas: "DirectorDashboard" "DirectorQuickAccess";
  }
}
.DirectorQuickAccess {
  grid-area: DirectorQuickAccess;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.DirectorQuickAccess .SalesSummary {
  position: relative;
  text-align: center;
  max-height: 150px;
  width: 80%;
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: rgb(248, 248, 248);
  border: 2px #0a6887 solid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "h4 h4 h4" "h4 h4 h4" "totalOrders TotalSales Net";
  margin-top: 10px;
}
.DirectorQuickAccess .SalesSummary:hover {
  border: #0a6887 2px solid;
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.2666666667);
}
.DirectorQuickAccess .SalesSummary h4 {
  grid-area: h4;
  display: grid;
  place-items: center;
}
.DirectorQuickAccess .SalesSummary .totalOrders {
  border-top: 2px #9A9A9A solid;
  grid-area: totalOrders;
  display: grid;
  place-items: center;
}
.DirectorQuickAccess .SalesSummary .totalOrders:hover {
  border-top: #0a6887 2px solid;
  color: #0a6887;
}
.DirectorQuickAccess .SalesSummary .totalOrders p {
  font-size: 80%;
  margin-bottom: 5px;
}
.DirectorQuickAccess .SalesSummary .TotalSales {
  border-top: 2px #9A9A9A solid;
  grid-area: TotalSales;
  display: grid;
  place-items: center;
}
.DirectorQuickAccess .SalesSummary .TotalSales:hover {
  border-top: #0a6887 2px solid;
  color: #0a6887;
}
.DirectorQuickAccess .SalesSummary .TotalSales p {
  font-size: 80%;
  margin-bottom: 5px;
}
.DirectorQuickAccess .SalesSummary .Net {
  border-top: 2px #9A9A9A solid;
  grid-area: Net;
  display: grid;
  place-items: center;
}
.DirectorQuickAccess .SalesSummary .Net:hover {
  border-top: #0a6887 2px solid;
  color: #0a6887;
}
.DirectorQuickAccess .SalesSummary .Net p {
  font-size: 80%;
  margin-bottom: 5px;
}

.DirectorDashboard {
  grid-area: DirectorDashboard;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr  1fr;
   grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

   grid-template-areas: 
   "three three three  three"
   "three three three  three"
   //"two two two  "
   "two two two two"
   " four four four four"
   "six six six six"
   "five five five one"
   ;
   */
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "three three" "two two";
  grid-gap: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.DirectorDashboard_Card {
  border-radius: 20px;
  height: 250px;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  padding: 15px;
  display: grid;
  place-items: center;
}
.DirectorDashboard_Card.one {
  display: grid;
  height: 80%;
  background-color: rgb(255, 255, 255);
  grid-area: one;
}
.DirectorDashboard_Card.two {
  height: 100%;
  width: 100%;
  max-height: 350px;
  margin: 15px;
  padding: 0;
  grid-area: two;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(25px, 50px) 1fr 1fr;
  grid-template-areas: "Title Title" "Content Content" "Content Content";
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.DirectorDashboard_Card.two .Title {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #0a6887;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  grid-area: Title;
  display: grid;
  text-align: center;
  place-items: center;
}
.DirectorDashboard_Card.two .Title p {
  font-size: 1rem;
  font-weight: 600;
}
.DirectorDashboard_Card.two a {
  color: rgb(17, 17, 17);
}
.DirectorDashboard_Card.two .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  grid-area: Content;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  margin: 0 auto;
}
.DirectorDashboard_Card.two .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  text-align: left;
  transition: 0.2s ease-in-out;
}
.DirectorDashboard_Card.two .Content .Order_dashboard:hover {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #0a6887;
  border-radius: 0;
  color: rgb(241, 241, 241);
  cursor: pointer;
}
.DirectorDashboard_Card.two .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}
.DirectorDashboard_Card.three {
  height: 100%;
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  grid-area: three;
  margin: 15px;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(25px, 50px) 1fr 1fr;
  grid-template-areas: "Title Title" "Content Content" "Content Content";
}
.DirectorDashboard_Card.three a {
  color: rgb(17, 17, 17);
}
.DirectorDashboard_Card.three .Title {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #0a6887;
  width: 100%;
  height: 100%;
  grid-area: Title;
  display: grid;
  text-align: center;
  place-items: center;
}
.DirectorDashboard_Card.three .Title p {
  font-size: 1rem;
  font-weight: 600;
}
.DirectorDashboard_Card.three .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  grid-area: Content;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  margin: 0 auto;
}
.DirectorDashboard_Card.three .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  text-align: left;
  transition: 0.2s ease-in-out;
}
.DirectorDashboard_Card.three .Content .Order_dashboard:hover {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #0a6887;
  cursor: pointer;
  color: rgb(241, 241, 241);
}
.DirectorDashboard_Card.three .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}
.DirectorDashboard_Card.four {
  grid-area: four;
  margin: 15px;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(25px, 50px) 1fr 1fr;
  grid-template-areas: "Title Title" "Content Content" "Content Content";
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.DirectorDashboard_Card.four .Title {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  grid-area: Title;
  display: grid;
  text-align: center;
  place-items: center;
}
.DirectorDashboard_Card.four .Title p {
  font-size: 1rem;
  font-weight: 600;
}
.DirectorDashboard_Card.four a {
  color: rgb(17, 17, 17);
}
.DirectorDashboard_Card.four .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  grid-area: Content;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  margin: 0 auto;
}
.DirectorDashboard_Card.four .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  text-align: left;
  transition: 0.2s ease-in-out;
}
.DirectorDashboard_Card.four .Content .Order_dashboard:hover {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #0a6887;
  border-radius: 0;
  color: rgb(241, 241, 241);
  cursor: pointer;
}
.DirectorDashboard_Card.four .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}
.DirectorDashboard_Card.five {
  background-color: rgb(255, 255, 255);
  grid-area: five;
}
.DirectorDashboard_Card.six {
  background-color: rgb(255, 255, 255);
  grid-area: six;
}

.SalesOrderPage {
  width: 98%;
  margin: 0 auto;
}
.SalesOrderPage .Sales_order_View {
  border-radius: 15px;
  border: 1px solid #0a6887;
}
.SalesOrderPage ._Info {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 15px auto;
  padding: 15px;
}
.SalesOrderPage ._Info ._Info__Order {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "_CustomerName _Created_Date_Time _SalesRep_Name" "_OrderNumber . .";
}
.SalesOrderPage ._Info ._Info__Order ._CustomerName {
  grid-area: _CustomerName;
  font-weight: 600;
  font-size: 15px;
}
.SalesOrderPage ._Info ._Info__Order ._Created_Date_Time {
  grid-area: _Created_Date_Time;
  font-weight: 600;
  font-size: 15px;
}
.SalesOrderPage ._Info ._Info__Order ._SalesRep_Name {
  grid-area: _SalesRep_Name;
  font-weight: 600;
  font-size: 15px;
}
.SalesOrderPage ._Info ._Info__Order ._OrderNumber {
  grid-area: _OrderNumber;
  font-weight: 600;
  font-size: 15px;
}
.SalesOrderPage ._Info .Customer {
  border-bottom: 2px solid #0a6887;
  grid-area: Customer;
}
.SalesOrderPage ._Info .Customer.bad {
  color: red;
  border-bottom: 2px solid red;
}
.SalesOrderPage ._Info .Customer.Good {
  color: #0a6887;
  border-bottom: 2px solid #0a6887;
}

.Order_Container_Main {
  border-radius: 15px;
  border: 1px solid #0a6887;
  background-color: aqua;
}

.Order {
  border-radius: 15px;
  margin: 15px 0;
  transition: all 0.3s ease-in-out;
}
.Order .OrderItems {
  padding: 15px;
}

.StateName {
  margin: 10px auto;
  font-size: 14px;
  text-align: center;
  font-weight: 900;
}

.Orderinfo {
  width: 80%;
  margin: 15px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 3px #0a6887 solid;
  grid-template-areas: "Vchseries Orderid All_Order_Items_Status ";
  font-size: 9px;
  font-weight: 600;
}
.Orderinfo .TaxType {
  grid-area: TaxType;
  border-bottom: 2px solid #0a6887;
}
.Orderinfo .Orderid {
  grid-area: Orderid;
  margin: 0 auto;
  font-size: 14px;
}
.Orderinfo .Vchseries {
  grid-area: Vchseries;
}
.Orderinfo .Created_DateTime {
  border-bottom: 2px solid #0a6887;
  grid-area: Created_DateTime;
}
.Orderinfo .Customer {
  border-bottom: 2px solid #0a6887;
  grid-area: Customer;
}
.Orderinfo .Customer.bad {
  color: red;
  border-bottom: 2px solid red;
}
.Orderinfo .Customer.Good {
  color: #0a6887;
  border-bottom: 2px solid #0a6887;
}
.Orderinfo .BrokerName {
  border-bottom: 2px solid #0a6887;
  grid-area: BrokerName;
}
.Orderinfo .GTotal {
  grid-area: GTotal;
  border-bottom: 2px solid #0a6887;
}
.Orderinfo .All_Order_Items_Status {
  grid-area: All_Order_Items_Status;
}

.Line_Item_approvalstate {
  display: inline-block;
  position: relative;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
select:focus {
  border-color: #0a6887;
}
select option:hover {
  background-color: #0a6887;
}

.Line_Item_approvalstate::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #0a6887;
}

@media screen and (min-width: 700px) {
  .Orderinfo {
    font-size: 15px;
  }
  .Orderinfo .Orderid {
    font-size: 18px;
  }
}
.StateName {
  color: #2b2b2b;
  padding: 5px;
  margin: 15px;
}

.Payment_Type {
  color: #0a6887;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  border-bottom: 2px solid #0a6887;
  padding: 15px;
}

.Order_Items_Table {
  width: 100%;
}

tr.Line_Item {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.Order_Items_Table {
  display: grid;
}

.Line_Item_order {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px); /* set a fixed width of 200px for each column */
  font-weight: lighter;
  grid-template-columns: 35px 0.1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  padding: 0;
  max-height: 35px;
  grid-template-areas: "rowcount selector productName brand  Line_Item_Qty Line_Item_Discount listPrice price total_amount profit status";
}
.Line_Item_order.titles {
  background-color: #0a6887;
  color: white;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
}
.Line_Item_order .rowcount {
  grid-area: rowcount;
}
.Line_Item_order .Selector {
  grid-area: Selector;
}
.Line_Item_order .brand {
  grid-area: brand;
}
.Line_Item_order .productName {
  grid-area: productName;
}
.Line_Item_order .Line_Item_Qty {
  grid-area: Line_Item_Qty;
}
.Line_Item_order .Line_Item_Discount {
  grid-area: Line_Item_Discount;
}
.Line_Item_order .qtydiscount {
  grid-area: qtydiscount;
}
.Line_Item_order .listPrice {
  grid-area: listPrice;
}
.Line_Item_order .price {
  grid-area: price;
}
.Line_Item_order .total_amount {
  grid-area: total_amount;
}
.Line_Item_order .profit {
  grid-area: profit;
}
.Line_Item_order .status {
  grid-area: status;
}

.Approved {
  border-bottom: 1px #0B5394 solid;
  background-color: rgba(196, 228, 244, 0.231372549);
}

/* Select the Tabs component */
.MuiTabs-root {
  /* Add some space at the bottom of the Tabs component */
  margin-bottom: 16px;
}

.Orderitems_Filter_tabs {
  display: grid;
  margin: 0 auto;
  width: 80%;
  overflow: auto;
}

.MuiTabs-scroller {
  overflow: auto;
}

/* Select all the Tab components */
.orderApproved.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #0B5394;
}

/* Select all the Tab components */
.Pending.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #3d3d3d;
}

/* Select all the Tab components */
.ReadyToPack.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #B45F06;
}

.Dispatch.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #3B7E31;
}

.ReadyToDispatch.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #7F6000;
}

.Declined.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #b40000;
}

.Invoiced.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #3a3a3a;
}

.Refund.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
  color: #4e4900;
}

.All.MuiTab-root {
  /* Set the font weight to bold */
  font-weight: bold;
  /* Set the font color to match the app's color scheme */
}

.ReadyToPack {
  background-color: rgba(252, 232, 178, 0.231372549);
  border-bottom: 1px #B45F06 solid;
}

.ReadyToDispatch {
  background-color: rgba(251, 213, 181, 0.231372549);
  border-bottom: 1px #7F6000 solid;
}

.Dispatch {
  border-bottom: 1px #3B7E31 solid;
  background-color: rgba(217, 234, 211, 0.231372549);
}

.Declined {
  border-bottom: 1px #b40000 solid;
  background-color: rgba(255, 231, 231, 0.231372549);
}

.Invoiced {
  background-color: rgba(54, 54, 54, 0.231372549);
  border-bottom: 1px #3a3a3a solid;
}

.Refund {
  background-color: rgba(211, 197, 0, 0.231372549);
  border-bottom: 1px #4e4900 solid;
}

.Pending {
  background-color: rgba(221, 221, 221, 0.231372549);
  border-bottom: 1px #3d3d3d solid;
}

.pending {
  background-color: rgba(221, 221, 221, 0.231372549);
  border-bottom: 1px #3d3d3d solid;
}

.InvoiceData {
  background-color: #e2e2e2;
  margin: 10px auto;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Order_Items_Table {
  margin: 0 auto;
  width: 100%;
}

.Payment_Type_summery {
  color: #14185B;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  padding: 5px;
  border-bottom: 3px solid #0a6887;
  text-transform: uppercase;
}
.Payment_Type_summery h2 {
  text-decoration: none;
}

.Order_Items_Payment_mode {
  width: 100%;
  margin: 15px auto;
}

.Order_Items_Credit_Period {
  margin: 15px auto;
}
.Titles_Cart_Line_Item {
  font-family: "open sans";
  color: white;
  font-weight: 400;
  font-size: 10px;
  display: grid;
  background-color: #0a6887;
  width: 100%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 0.1fr 1fr 2fr 0.7fr 0.7fr;
  margin: 0 auto;
  grid-template-areas: " Name Name  Name  Name  ACode " " Supplier Supplier Supplier   Brand Brand  " "Qty Discount List_Price   Price   Amount";
  margin: 0 auto;
}
.Titles_Cart_Line_Item_Brand {
  grid-area: Titles_Brand;
}
.Titles_Cart_Line_Item_Supplier {
  grid-area: Titles_Supplier;
}
.Titles_Cart_Line_Item_Name {
  grid-area: Titles_Name;
}
.Titles_Cart_Line_Item_ACode {
  grid-area: Titles_ACode;
}
.Titles_Cart_Line_Item_Qty {
  grid-area: Titles_Qty;
  text-align: right;
  padding: 2px 0;
}
.Titles_Cart_Line_Item_List_Price {
  text-align: right;
  grid-area: Titles_List_Price;
}
.Titles_Cart_Line_Item_Discount {
  grid-area: Titles_Discount;
  text-align: right;
  padding: 2px 0;
}
.Titles_Cart_Line_Item_Price {
  grid-area: Titles_Price;
  text-align: right;
  padding: 2px 0;
}
.Titles_Cart_Line_Item .Titles_Cart_Line_Remove {
  text-align: center;
  grid-area: Titles_Remove;
}
.Titles_Cart_Line_Item_Amount {
  text-align: right;
  grid-area: Titles_Amount;
  padding: 2px 0;
}

.Cart_Line_Item {
  display: grid;
  width: 100%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 0.1fr 1fr 2fr 0.7fr 0.7fr;
  margin: 0 auto;
  font-weight: 400;
  font-size: 10px;
  grid-template-areas: " Name Name  Name  Name  ACode " " Supplier Supplier Supplier   Brand   Brand" "Qty Discount List_Price   Price   Amount";
  font-size: 9px;
  margin: 0 auto;
}
.Cart_Line_Item_Brand {
  grid-area: Brand;
}
.Cart_Line_Item_Supplier {
  grid-area: Supplier;
}
.Cart_Line_Item_Name {
  grid-area: Name;
}
.Cart_Line_Item_ACode {
  grid-area: ACode;
}
.Cart_Line_Item_Qty {
  grid-area: Qty;
  text-align: right;
  padding: 2px 0;
}
.Cart_Line_Item_List_Price {
  text-align: right;
  grid-area: List_Price;
}
.Cart_Line_Item_Discount {
  grid-area: Discount;
  text-align: right;
  padding: 2px 0;
}
.Cart_Line_Item_Price {
  grid-area: Price;
  text-align: right;
  padding: 2px 0;
}
.Cart_Line_Item_Amount {
  text-align: right;
  grid-area: Amount;
  padding: 2px 0;
}

@media screen and (min-width: 700px) {
  .Line_Item {
    grid-template-rows: 1fr;
    grid-template-columns: 0.1fr 3fr 1fr 0.5fr 1fr 1fr 1fr 0.5fr minmax(250px, 1fr);
    font-size: 17px;
    grid-template-areas: "rowcount Name Brand  Qty List_Price  Discount Price  Amount Profitablity approvalstate";
  }
  .Titles_Cart_Line_Item {
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 13px;
    grid-template-areas: "  Titles_Name Titles_Brand Titles_Qty Titles_List_Price Titles_Discount Titles_Price Titles_Amount ";
  }
  .Cart_Line_Item {
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 13px;
    grid-template-areas: "  Name Brand Qty List_Price Discount Price Amount";
  }
}
.line-product-summary-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-product-summary-modal .Modal_Summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "table notes_List" "profit profit";
}
.line-product-summary-modal .Modal_Summary .table {
  grid-area: table;
}
.line-product-summary-modal .Modal_Summary .notes_List {
  grid-area: notes_List;
}
.line-product-summary-modal .Modal_Summary .profit {
  grid-area: profit;
}

.line-product-summary-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.line-product-summary-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.icon-cell {
  position: relative;
}

.icon {
  font-size: 1.5rem;
}

.note-icon {
  color: orange;
  font-size: 1rem;
}

@keyframes warningAnimation {
  0%, 100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
@keyframes noteAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
}

.invoicetable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  margin: 0 auto;
}
.invoicetable thead {
  background-color: #f5f5f5;
}
.invoicetable thead tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.invoicetable tbody tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: left;
}
.invoicetable tfoot tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: left;
}

.State_selector_orders {
  font-weight: 800;
}
.State_selector_orders.Invoiced {
  color: #3a3a3a;
}
.State_selector_orders.Refund {
  color: #000;
}
.State_selector_orders.Pending {
  color: #3d3d3d;
}
.State_selector_orders.pending {
  color: #3d3d3d;
}
.State_selector_orders.Approved {
  color: #0B5394;
}
.State_selector_orders.Declined {
  color: #b40000;
}
.State_selector_orders.ReadyToPack {
  color: #B45F06;
}
.State_selector_orders.ReadyToDispatch {
  color: #7F6000;
}
.State_selector_orders.Dispatch {
  color: #3B7E31;
}
.State_selector_orders .options_Invoiced {
  color: #3a3a3a;
  font-weight: 800;
}
.State_selector_orders .options_Refund {
  color: #b40000;
  font-weight: 800;
}
.State_selector_orders .options_Pending {
  color: #3d3d3d;
  font-weight: 800;
}
.State_selector_orders .options_Approved {
  color: #0B5394;
  font-weight: 800;
}
.State_selector_orders .options_Declined {
  color: #b40000;
  font-weight: 800;
}
.State_selector_orders .options_ReadyToPack {
  color: #B45F06;
  font-weight: 800;
}
.State_selector_orders .options_ReadyToDispatch {
  color: #7F6000;
  font-weight: 800;
}
.State_selector_orders .options_Dispatch {
  color: #3B7E31;
  font-weight: 800;
}
.State_selector_orders .options_Refund {
  color: #4e4900;
  font-weight: 800;
}

.register {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: hsl(0, 0%, 100%);
}
@media screen and (min-width: 1366px) {
  .register {
    padding: 0;
  }
}
.register h1 {
  line-height: 100%;
  color: #2b2b2b;
  margin-bottom: 40px;
}
.register-progressbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  counter-reset: step;
}
@media screen and (min-width: 1366px) {
  .register-progressbar {
    width: 560px;
  }
}
.register-progressbar li {
  position: relative;
  text-align: center;
  list-style: none;
  color: white;
  margin: 0 10px;
}
@media screen and (min-width: 1366px) {
  .register-progressbar li {
    margin: 0 15px;
    font-size: 20px;
  }
}
.register-progressbar li:nth-of-type(2)::after {
  content: "";
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-100%);
  z-index: -1;
  width: 30px;
  height: 3px;
  background: #9A9A9A;
  transition: 0.2s ease-in-out;
}
.register-progressbar li:nth-of-type(2).active::after {
  background: #0a6887;
}
.register-progressbar li::before {
  background-color: #0a6887;
  content: counter(step);
  counter-increment: step;
  border: 2px solid #9A9A9A;
  border-radius: 100%;
  color: #9A9A9A;
  background: white;
  display: block;
  transition: 0.2s ease-in-out;
  width: 30px;
  line-height: 30px;
}
@media screen and (min-width: 1366px) {
  .register-progressbar li::before {
    width: 45px;
    line-height: 45px;
  }
}
.register-progressbar li.active::before {
  border: 2px solid #0a6887;
  color: white;
  background: #0a6887;
}
.register-form {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .register-form {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1366px) {
  .register-form {
    margin-bottom: 125px;
  }
}
@media screen and (max-width: 480px) {
  .register-form-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form-wrapper {
    width: 85%;
  }
}
@media screen and (min-width: 1366px) {
  .register-form-wrapper {
    width: 560px;
  }
}
.register-form .inline-btns input[type=submit],
.register-form .inline-btns input[type=button] {
  color: white;
  width: 50%;
  background-color: #0a6887;
  border: 1px solid #0a6887;
  margin-top: 75px;
}
@media screen and (max-width: 480px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}
@media screen and (min-width: 1366px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 20px;
    padding: 17.5px 0;
    border-radius: 20px;
  }
}
.register-form .inline-btns input[type=submit]#register-prev,
.register-form .inline-btns input[type=button]#register-prev {
  color: #0a6887;
  background-color: white;
  border: 1px solid transparent;
  margin-right: 30px;
}
.register-form .inline-btns input[type=submit]#register-prev:hover,
.register-form .inline-btns input[type=button]#register-prev:hover {
  border: 1px solid #0a6887;
}
@media screen and (max-width: 480px) {
  .register-form .inline-btns input[type=submit]#register-prev,
  .register-form .inline-btns input[type=button]#register-prev {
    margin-right: 0;
  }
}
.register-form fieldset {
  border-radius: 30px;
  padding: 30px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  box-sizing: border-box;
  position: absolute;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
@media screen and (min-width: 1366px) {
  .register-form fieldset {
    width: 560px;
    padding: 60px;
  }
}
.register-form fieldset.inactive {
  visibility: hidden;
  opacity: 0%;
  left: 100%;
}
@media screen and (min-width: 1366px) {
  .register-form fieldset.inactive {
    left: 50%;
    transform: scale(0.75);
  }
}
.register-form fieldset.active {
  visibility: visible;
  opacity: 100%;
  left: 0%;
}
.register-form fieldset.completed {
  opacity: 0%;
}
@media screen and (min-width: 1366px) {
  .register-form fieldset.completed {
    transform: scale(0.75);
  }
}
.register-form fieldset h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #2b2b2b;
}
@media screen and (min-width: 1366px) {
  .register-form fieldset h2 {
    margin-bottom: 50px;
  }
}
.register-form fieldset:nth-of-type(1) h2 {
  margin-bottom: 15px;
}
@media screen and (min-width: 1366px) {
  .register-form fieldset:nth-of-type(1) h2 {
    margin-bottom: 25px;
  }
}
.register-form fieldset .floating-input {
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .register-form fieldset .floating-input {
    margin-bottom: 20px;
  }
}
.login {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  place-content: center;
  padding: 0 30px;
  margin: 0 auto;
  background-color: hsl(0, 0%, 100%);
}
.login-form-wrapper {
  margin: 0 auto;
}
@media screen and (max-width: 480px) {
  .login {
    text-align: center;
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login {
    text-align: center;
  }
  .login-form-wrapper {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1366px) {
  .login {
    text-align: center;
    padding: 0;
  }
}
.login h1 {
  line-height: 100%;
  color: #0a6887;
  margin-bottom: 40px;
}
.login-form {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .login-form {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1366px) {
  .login-form {
    margin-bottom: 125px;
  }
}
@media screen and (max-width: 480px) {
  .login-form-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form-wrapper {
    width: 75%;
  }
}
@media screen and (min-width: 1366px) {
  .login-form-wrapper {
    width: 450px;
  }
}
.login-form .inline-btns input[type=submit],
.login-form .inline-btns input[type=button] {
  color: white;
  width: 50%;
  background-color: #0a6887;
  border: 1px solid #0a6887;
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}
@media screen and (min-width: 1366px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 20px;
    padding: 18px 0;
  }
}
.login-form fieldset {
  border-radius: 25px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  box-sizing: border-box;
  position: absolute;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .login-form fieldset {
    padding: 30px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form fieldset {
    padding: 30px 45px;
  }
}
@media screen and (min-width: 1366px) {
  .login-form fieldset {
    padding: 40px;
  }
}
.login-form fieldset .floating-input {
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .login-form fieldset .floating-input {
    margin-bottom: 20px;
  }
}
.login-form-forgot {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 90%;
  font-weight: 500;
  text-decoration: underline;
  color: blue !important;
}

.reset,
.forgot {
  width: 100%;
  display: grid;
  /*  flex-direction: column;
    justify-content: flex-start;*/
  align-items: center;
  place-content: center;
  padding: 0 30px;
  position: absolute;
  /* top: 50%;
      transform: translateY(-150%);
  */
  background-color: hsl(0, 0%, 100%);
}
@media screen and (min-width: 1366px) {
  .reset,
  .forgot {
    padding: 0;
  }
}
.reset h1,
.forgot h1 {
  line-height: 100%;
  color: #0a6887;
  margin-bottom: 40px;
}
.reset-form,
.forgot-form {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1366px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 125px;
  }
}
@media screen and (max-width: 480px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 75%;
  }
}
@media screen and (min-width: 1366px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 450px;
  }
}
@media screen and (max-width: 480px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}
@media screen and (min-width: 1366px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 20px;
    padding: 18px 0;
  }
}
.reset-form .stacked-btns input[type=submit],
.forgot-form .stacked-btns input[type=submit] {
  color: white;
  border: 1px solid #0a6887;
  background-color: #0a6887;
}
.reset-form .stacked-btns input[type=button],
.forgot-form .stacked-btns input[type=button] {
  color: #0a6887;
  background-color: white;
}
.reset-form fieldset,
.forgot-form fieldset {
  border-radius: 25px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  box-sizing: border-box;
  position: absolute;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 30px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 30px 45px;
  }
}
@media screen and (min-width: 1366px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 40px;
  }
}
.reset-form a,
.forgot-form a {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 90%;
  font-weight: 500;
  text-decoration: underline;
  color: blue !important;
}

.dash {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: inherit;
  overflow: hidden;
}
.dash-nav {
  height: 100%;
  width: 70px;
  overflow: hidden auto;
  background-color: #2b2b2b;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .dash-nav {
    width: 50px;
  }
}
@media screen and (max-width: 480px) {
  .dash-nav.open {
    width: 70%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-nav.open {
    width: 25%;
  }
}
@media screen and (min-width: 1366px) {
  .dash-nav.open {
    width: 250px;
  }
}
.dash-nav.open .dash-nav-menu a {
  -moz-column-gap: 10px;
       column-gap: 10px;
}
@media screen and (max-width: 480px) {
  .dash-nav.open .dash-nav-menu a {
    -moz-column-gap: 0px;
         column-gap: 0px;
  }
}
.dash-nav.open .dash-nav-menu a svg {
  transition: fill 0.2s ease-in-out, margin-right 0.2s ease-in-out 0s;
}
.dash-nav.open .dash-nav-toggle {
  -moz-column-gap: 10px;
       column-gap: 10px;
}
@media screen and (max-width: 480px) {
  .dash-nav.open .dash-nav-toggle {
    -moz-column-gap: 0px;
         column-gap: 0px;
  }
}
.dash-nav.open .dash-nav-toggle svg {
  transition: fill 0.2s ease-in-out, transform 0.2s ease-in-out, margin-right 0.2s ease-in-out 0s;
  transform: rotate(180deg);
}
.dash-nav a,
.dash-nav button {
  display: block;
  white-space: nowrap;
}
@media screen and (max-width: 480px) {
  .dash-nav a,
  .dash-nav button {
    width: 70vw;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-nav a,
  .dash-nav button {
    width: 25vw;
  }
}
@media screen and (min-width: 1366px) {
  .dash-nav a,
  .dash-nav button {
    width: 250px;
  }
}
.dash-nav-menu {
  width: 100%;
}
.dash-nav-menu a {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  -moz-column-gap: 25px;
       column-gap: 25px;
  font-size: 18px;
  color: hsl(0, 0%, 100%);
  font-weight: 500;
  line-height: 100%;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  padding: 15px 20px;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .dash-nav-menu a {
    font-size: 16px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    padding: 15px 12.5px;
  }
}
.dash-nav-menu a:hover, .dash-nav-menu a.active {
  color: #4952a3;
}
.dash-nav-menu a:hover svg, .dash-nav-menu a.active svg {
  fill: #4952a3;
}
.dash-nav-menu a:hover::before, .dash-nav-menu a.active::before {
  left: 0;
}
.dash-nav-menu a::before {
  content: "";
  position: absolute;
  background-color: hsl(0, 0%, 100%);
  bottom: 0;
  left: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  transition: left 0.5s ease-out;
}
.dash-nav-menu a.active::before {
  left: 0;
}
.dash-nav-menu a svg {
  fill: hsl(0, 0%, 100%);
  transition: fill 0.2s ease-in-out, margin-right 0.2s ease-in-out 0.2s;
  display: inline;
  z-index: 1;
  width: 28px;
}
@media screen and (max-width: 480px) {
  .dash-nav-menu a svg {
    width: 24px;
  }
}
.dash-nav-toggle {
  display: grid !important;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  -moz-column-gap: 25px;
       column-gap: 25px;
  font-size: 18px;
  color: hsl(0, 0%, 100%);
  font-weight: 500;
  line-height: 100%;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  padding: 15px 25px;
  text-align: start;
}
@media screen and (max-width: 480px) {
  .dash-nav-toggle {
    font-size: 16px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    padding: 15px 12.5px;
  }
}
.dash-nav-toggle:hover, .dash-nav-toggle.active {
  color: #4952a3;
}
.dash-nav-toggle:hover svg, .dash-nav-toggle.active svg {
  fill: #4952a3;
}
.dash-nav-toggle.active {
  background-color: white;
}
.dash-nav-toggle svg {
  fill: hsl(0, 0%, 100%);
  width: 28px;
  transition: fill 0.2s ease-in-out, transform 0.2s ease-in-out, margin-right 0.2s ease-in-out 0.2s;
  display: inline;
}
@media screen and (max-width: 480px) {
  .dash-nav-toggle svg {
    width: 24px;
  }
}
.dash-content {
  position: absolute;
  top: 0%;
  right: 0%;
  overflow-y: auto;
  height: 100%;
  width: calc(100% - 70px);
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 100%);
}
@media screen and (max-width: 480px) {
  .dash-content {
    padding: 15px;
    width: calc(100% - 50px);
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content {
    padding: 25px 40px;
  }
}
@media screen and (min-width: 1366px) {
  .dash-content {
    padding: 25px 80px;
  }
}
@media screen and (max-width: 480px) {
  .dash-content.open {
    right: calc(-70% + 50px);
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content.open {
    right: calc(-25% + 70px);
  }
}
@media screen and (min-width: 1366px) {
  .dash-content.open {
    right: -180px;
  }
}
.dash-content .dash-section {
  width: 100%;
}
.dash-content .dash-section-title {
  color: #2b2b2b;
  text-align: start;
  line-height: 100%;
  margin-bottom: 30px;
}
@media screen and (min-width: 481px) {
  .dash-content .dash-section-title {
    margin-bottom: 50px;
  }
}
.dash-content .dash-section-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 75px;
       column-gap: 75px;
  row-gap: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 100px;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav {
    padding: 0px;
    grid-template-columns: unset;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;
  }
}
.dash-content .dash-section-nav-title {
  padding: 0 75px;
  margin-bottom: 50px;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav-title {
    padding: 0px;
    margin-bottom: 30px;
  }
}
.dash-content .dash-section-nav-link {
  box-sizing: border-box;
  padding: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  color: #0a6887;
  border: 1px solid #0a6887;
  border-radius: 25px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  font-size: 175%;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav-link {
    font-size: 150%;
    padding: 30px;
  }
}
.dash-content .dash-section-nav-link:hover {
  background-color: #0a6887;
  color: hsl(0, 0%, 100%);
}
.dash-content .dash-section-nav-link:hover svg {
  fill: hsl(0, 0%, 100%);
}
.dash-content .dash-section-nav-link svg {
  transition: 0.3s ease-in-out;
  fill: #0a6887;
  height: auto;
  margin-left: 50px;
}
@media screen and (min-width: 1366px) {
  .dash-content .dash-section-nav.home-nav {
    grid-template-columns: repeat(3, 1fr);
  }
}
.dash-content .dash-section-search {
  width: 100%;
  margin-bottom: 25px;
}
.dash-content .dash-section-search-input {
  position: relative;
}
.dash-content .dash-section-search-input input {
  width: 100%;
}
.dash-content .dash-section-search-input input:focus ~ button {
  border-left-color: #0a6887;
}
.dash-content .dash-section-search-input button {
  position: absolute;
  top: 0%;
  height: 100%;
  padding: 10px 15px;
  transition: 0.2s ease-in-out;
}
.dash-content .dash-section-search-input button:nth-of-type(1) {
  right: 0%;
  border-radius: 0 15px 15px 0;
  border: 1px solid #2b2b2b;
  border-left-width: 1px;
  background-color: #2b2b2b;
}
.dash-content .dash-section-search-input button:nth-of-type(1):focus svg, .dash-content .dash-section-search-input button:nth-of-type(1):hover svg {
  fill: #4952a3;
}
.dash-content .dash-section-search-input button:nth-of-type(1) svg {
  fill: #f1f1f1;
  transition: 0.2s ease-in-out;
}
.dash-content .dash-section-search-input button:nth-of-type(2) {
  right: 54px;
}
.dash-content .dash-section-search-input button:nth-of-type(2):hover svg {
  fill: rgb(248, 81, 73);
}
.dash-content .dash-section-search-input button:nth-of-type(2) svg {
  fill: #2b2b2b;
  transition: 0.2s ease-in-out;
  height: 15px;
}
.dash-content .dash-section-search-req {
  color: #2b2b2b;
  font-size: 90%;
  font-weight: 400;
  line-height: 100%;
  display: block;
  margin: 10px 0 0 10px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-btns {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
  }
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-btns {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1366px) {
  .dash-content .dash-section-btns {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: 100px;
  }
}
.dash-content .dash-section-btns-btn {
  display: grid;
  grid-template-columns: 15px auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  border: 1px solid #0a6887;
  background-color: #0a6887;
  border-radius: 15px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 100%;
  padding: 15px;
  width: -moz-max-content;
  width: max-content;
  transition: 0.2s ease-in-out;
  color: white;
}
@media screen and (min-width: 481px) {
  .dash-content .dash-section-btns-btn {
    grid-template-columns: 15px auto;
  }
}
@media screen and (min-width: 1366px) {
  .dash-content .dash-section-btns-btn:not(:last-of-type) {
    margin-left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-btns-btn {
    padding: 12.5px;
    width: auto;
  }
}
.dash-content .dash-section-btns-btn svg {
  height: 15px;
  fill: white;
}
.dash-content .dash-section-btns-btn:hover {
  opacity: 0.9;
}
.dash-content .dash-section-sort, .dash-content .dash-section-filter {
  z-index: 999;
  position: fixed;
  top: 0%;
  right: 0%;
  transform: translateX(100%);
  width: 25%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0px;
  padding: 30px;
  transition: transform 0.3s ease-in-out;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-sort, .dash-content .dash-section-filter {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content .dash-section-sort, .dash-content .dash-section-filter {
    width: 50%;
  }
}
.dash-content .dash-section-sort.open, .dash-content .dash-section-filter.open {
  transform: translateX(0%);
}
.dash-content .dash-section-sort-top, .dash-content .dash-section-filter-top {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #9A9A9A;
}
.dash-content .dash-section-sort-top button, .dash-content .dash-section-filter-top button {
  padding: 0;
}
.dash-content .dash-section-sort-top button svg, .dash-content .dash-section-filter-top button svg {
  fill: #2b2b2b;
  transform: rotate(180deg);
  height: 17.5px;
}
.dash-content .dash-section-sort-top h3, .dash-content .dash-section-filter-top h3 {
  display: inline;
}
.dash-content .dash-section-sort p, .dash-content .dash-section-filter p {
  font-weight: 500;
  line-height: 100%;
  font-size: 16px;
  margin-bottom: 5px;
}
.dash-content .dash-section-sort-filters-single:not(:first-of-type), .dash-content .dash-section-filter-filters-single:not(:first-of-type) {
  margin-top: 15px;
}
.dash-content .dash-section-sort-filters-single-items, .dash-content .dash-section-filter-filters-single-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.dash-content .dash-section-sort-filters-single-item, .dash-content .dash-section-filter-filters-single-item {
  display: inline;
}
.dash-content .dash-section-sort-filters-single-item input, .dash-content .dash-section-filter-filters-single-item input {
  margin-right: 5px;
}
.dash-content .dash-section-sort-filters-single-item label, .dash-content .dash-section-filter-filters-single-item label {
  margin-right: 15px;
}
.dash-content .dash-section-sort-items, .dash-content .dash-section-filter-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.dash-content .dash-section-view {
  overflow: auto hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-view {
    padding: 0;
  }
}
@media screen and (min-width: 1366px) {
  .dash-content .dash-section-view {
    padding: 5px 20px 0;
  }
}
.dash-content .dash-section-view-btns {
  width: -moz-max-content;
  width: max-content;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 15px 15px 0 0;
  background-color: #2b2b2b;
}
.dash-content .dash-section-view-btns.empty {
  border-radius: 15px;
}
.dash-content .dash-section-view-btns button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
}
.dash-content .dash-section-view-btns button svg {
  height: 24px;
  width: auto;
  fill: hsl(0, 0%, 100%);
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-view-btns button {
    padding: 15px;
  }
  .dash-content .dash-section-view-btns button svg {
    height: 20px;
  }
}
.dash-content .dash-section-view-btns-sortbtn svg {
  stroke: hsl(0, 0%, 100%);
  stroke-width: 0.5px;
}
.dash-content .dash-section-view-btns-orderbtn.desc svg {
  transform: rotate(180deg);
}
.dash-content .dash-section-view table {
  width: 100%;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-collapse: separate !important;
  border-spacing: 0;
  border-radius: 0 15px 15px 15px;
  overflow: hidden;
}
.dash-content .dash-section-view table thead {
  color: #f1f1f1;
  background-color: #2b2b2b;
  width: 100%;
}
.dash-content .dash-section-view table thead th {
  position: sticky;
  top: 0;
  padding: 20px 30px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-view table thead th {
    padding: 20px 10px;
  }
}
.dash-content .dash-section-view table tbody {
  width: 100%;
}
.dash-content .dash-section-view table tbody tr {
  background-color: white;
  opacity: 0.9;
  transition: 0.1s ease-in-out;
}
.dash-content .dash-section-view table tbody tr:hover {
  opacity: 1;
}
.dash-content .dash-section-view table tbody tr td {
  color: #2b2b2b;
  padding: 20px 30px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-view table tbody tr td {
    padding: 10px;
  }
}
.dash-content .dash-section-view table tbody tr td a {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  padding: 10px 15px;
  width: -moz-max-content;
  width: max-content;
  transition: 0.2s ease-in-out;
  color: #0a6887;
}
.dash-content .dash-section-view table tbody tr td a:hover {
  color: #4952a3;
}
.dash-content .dash-section-view table.outbound-table tbody {
  width: 100%;
}
.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type=button] {
  display: block;
  font-weight: 500;
  line-height: 100%;
  padding: 15px 20px;
  width: -moz-max-content;
  width: max-content;
  height: 100%;
  transition: 0.2s ease-in-out;
}
.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type=button]:nth-of-type(1) {
  border: 1px solid #0a6887;
  background-color: hsl(0, 0%, 100%);
  color: #0a6887;
  margin-right: 10px;
}
.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type=button]:nth-of-type(2) {
  border: 1px solid rgb(248, 81, 73);
  background-color: hsl(0, 60%, 98%);
  color: rgb(248, 81, 73);
}
.dash-content .dash-section-view-loadmore {
  width: 100%;
  padding: 12.5px 15px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #0a6887;
  background-color: #0a6887;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;
  align-self: center;
}
.dash-content .dash-section-view-loadmore:hover {
  opacity: 0.9;
}
.dash-content .dash-section-view-msg {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  color: #9A9A9A;
  font-weight: 500;
  text-align: center;
  transition: 0.2s ease-in-out;
  align-self: center;
}
.dash-content .dash-section-form {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: hsl(0, 0%, 100%);
  box-sizing: border-box;
}
.dash-content .dash-section-form-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dash-content .dash-section-form-top button {
  padding: 30px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-top button {
    padding: 20px;
  }
}
.dash-content .dash-section-form-top button:hover svg {
  opacity: 0.9;
}
.dash-content .dash-section-form-top button svg {
  fill: #2b2b2b;
  transform: rotate(180deg);
  transition: 0.2s ease-in-out;
}
.dash-content .dash-section-form-top h2 {
  display: inline;
}
.dash-content .dash-section-form-content {
  box-sizing: border-box;
  padding: 30px 75px;
  /*form{
      border-radius: 20px;
      background-color: white;
      box-shadow: $shadow;

      padding: 50px;

      @media screen and (max-width: $mobileMax) {
          padding: 30px;
      }
  }*/
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content .dash-section-form-content {
    padding: 30px 50px;
  }
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-content {
    padding: 30px;
  }
}
.dash-content .dash-section-form-content h3 {
  margin-bottom: 25px;
  line-height: 100%;
}
.dash-content .dash-section-form-content .labeled-input {
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-content .labeled-input {
    margin-bottom: 20px;
  }
}
.dash-content .dash-section-form-btns {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 75px 100px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-btns {
    padding: 10px 30px 40px;
  }
}
.dash-content .dash-section-form-btns button,
.dash-content .dash-section-form-btns input[type=submit],
.dash-content .dash-section-form-btns input[type=button] {
  font-size: 95%;
  padding: 15px 20px;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-btns button,
  .dash-content .dash-section-form-btns input[type=submit],
  .dash-content .dash-section-form-btns input[type=button] {
    width: 100%;
  }
}
.dash-content .dash-section-form-danger {
  display: grid;
  row-gap: 30px;
  box-sizing: border-box;
  width: 75%;
  padding: 25px 40px;
  margin: 75px auto 100px;
  border: 1px solid rgb(248, 81, 73);
  border-radius: 20px;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-form-danger {
    width: 100%;
    padding: 20px 20px;
    margin: 50px auto;
  }
}
.dash-content .dash-section-form-danger button,
.dash-content .dash-section-form-danger input[type=submit],
.dash-content .dash-section-form-danger input[type=button] {
  font-size: 95%;
  padding: 15px 20px;
  height: -moz-max-content;
  height: max-content;
  margin: auto;
}
@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-danger button,
  .dash-content .dash-section-form-danger input[type=submit],
  .dash-content .dash-section-form-danger input[type=button] {
    width: 100%;
  }
}
.dash-content .dash-section-form-danger-section {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.dash-content .dash-section-form-danger-section input {
  width: 100%;
}
@media screen and (max-width: 1365px) {
  .dash-content .dash-section-form-danger-section {
    display: flex;
    flex-direction: column;
  }
  .dash-content .dash-section-form-danger-section div {
    margin-bottom: 10px;
  }
}
.dash-content .dash-section-form .inline-btns {
  justify-content: flex-end;
}
@media screen and (min-width: 1366px) {
  .dash-content .dash-section-form .inline-btns input {
    width: -moz-max-content !important;
    width: max-content !important;
  }
}
.dash-content .dash-section-form-addbtn, .dash-content .dash-section-form-editbtn, .dash-content .dash-section-form-greenbtn {
  background-color: #0a6887;
  color: white;
  border: 1px solid #0a6887 !important;
}
.dash-content .dash-section-form-cancelbtn, .dash-content .dash-section-form-redbtn {
  background-color: transparent;
  color: rgb(248, 81, 73);
  border: 1px solid rgb(248, 81, 73) !important;
}
@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-cancelbtn, .dash-content .dash-section-form-redbtn {
    margin-right: 15px;
  }
}
.dash-content .dash-section-form-disablebtn, .dash-content .dash-section-form-deletebtn {
  background-color: transparent;
  color: rgb(248, 81, 73);
  border: 1px solid rgb(248, 81, 73) !important;
}
@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-disablebtn, .dash-content .dash-section-form-deletebtn {
    margin-right: 15px;
  }
}
.dash-content .dash-section-form-disablebtn:hover, .dash-content .dash-section-form-deletebtn:hover {
  color: hsl(0, 0%, 100%);
  background-color: rgb(248, 81, 73);
}
.dash-content .dash-section-form-enablebtn {
  background-color: transparent;
  color: #0a6887;
  border: 1px solid #0a6887 !important;
}
@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-enablebtn {
    margin-right: 15px;
  }
}
.dash-content .dash-section-form-enablebtn:hover {
  color: hsl(0, 0%, 100%);
  background-color: #0a6887;
}
.dash-details-section {
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  padding: 50px;
  margin-bottom: 35px;
}
@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-details-section {
    padding: 35px;
  }
}
@media screen and (max-width: 480px) {
  .dash-details-section {
    padding: 30px;
  }
}

.CustomerList {
  width: 90%;
  margin: 0 auto;
}

.customerinformation {
  width: 90%;
  margin: 0 auto;
}
.customerinformation .CryptoCard {
  background-color: #0a6887;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  font-family: "Poppins", sans-serif;
}

.orderview {
  width: 90%;
  margin: 0 auto;
}
.orderview .CryptoCard {
  background-color: #0a6887;
  border-radius: 15px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}

.home-tabs-container {
  display: grid;
  place-content: center;
}
.home-tabs-container .home-tabs {
  border-bottom: 1px solid #ccc;
  overflow-x: auto;
  width: 100%;
}
.home-tabs-container .home-tabs .Mui-selected .MuiTabIndicator-root {
  background-color: #ff0000;
}
.home-tabs-container .home-tab-label {
  text-transform: capitalize;
  font-weight: 800;
}
.home-tabs-container .MuiTabs-indicator {
  background-color: #ff0000;
}
.home-tabs-container .MuiTab-root.Mui-selected {
  color: #ff0000;
}

.tabs-container_Invoice {
  max-width: 80%; /* or whatever value you want */
}

.Payemt_modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  margin: 0 auto;
}
.Payemt_modal-content .modal-heading {
  margin-top: 0;
}
.Payemt_modal-content .modal-label {
  font-weight: bold;
  margin-bottom: 5px;
}
.Payemt_modal-content .modal-value {
  margin-top: 0;
}

.modal-overlay {
  width: 100%;
  overflow: auto;
}
.modal-overlay._customers {
  background-color: rgba(238, 238, 238, 0.178);
  width: 100%;
}
.modal-overlay .modal {
  width: 80vw;
  overflow: auto;
}

.Create_Payment_Voucher {
  margin: 0 auto;
  width: 80%;
}
.Create_Payment_Voucher .Items {
  margin: 0 auto;
  width: 100%;
  border: 3px solid #0a6887;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}
.Create_Payment_Voucher .Items table {
  width: 100%;
}
.Create_Payment_Voucher .Items table .row_item {
  border-bottom: 1px solid #404040;
}

.Add_itemVoucher {
  width: 100%;
  text-align: center;
}

.PaymentMode {
  display: grid;
}

.voucherinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 1fr 1fr;
  grid-template-areas: "payee PaymentMode" "payee selectcategory";
}
.voucherinfo .selectcategory {
  grid-area: selectcategory;
}
.voucherinfo .PaymentMode {
  grid-area: PaymentMode;
}
.voucherinfo .payee {
  grid-area: payee;
}

.voucher_View_Row {
  width: 80%;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 10px auto;
  border-bottom: grey 1px solid;
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "Voucer_Date voucher_Id invoiceNumber   payee Paymentmode VoucherTotal";
}
.voucher_View_Row .voucher_Id {
  grid-area: voucher_Id;
}
.voucher_View_Row .Voucer_Date {
  grid-area: Voucer_Date;
}
.voucher_View_Row .VoucherTotal {
  grid-area: VoucherTotal;
  text-align: right;
}
.voucher_View_Row .Paymentmode {
  grid-area: Paymentmode;
}
.voucher_View_Row .payee {
  grid-area: payee;
}
.voucher_View_Row.verified {
  background-color: rgba(37, 158, 212, 0.3);
}
.voucher_View_Row.approved {
  background-color: rgba(37, 212, 66, 0.3);
}
.voucher_View_Row.declined {
  background-color: rgba(255, 0, 0, 0.281);
}

.voucherView {
  width: 100%;
  margin: 0 auto;
}
.voucherView .pagination-bar {
  margin: 0 auto;
  display: grid;
  place-content: center;
}

.Voucher_view_singular {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "interactions voucherdetails" "voucheritems voucheritems";
}
.Voucher_view_singular .interactions {
  grid-area: interactions;
}
.Voucher_view_singular .voucherdetails {
  grid-area: voucherdetails;
}
.Voucher_view_singular .voucheritems {
  grid-area: voucheritems;
}

.printvoucher {
  margin: 0 auto;
  width: 100%;
  display: grid;
}
.printvoucher.hidden {
  display: none;
}
.printvoucher .letterhead {
  margin: 20px;
  max-height: 100px;
}
.printvoucher .voucherbody .voucherdetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 0 auto;
  width: 90%;
}
.printvoucher .voucherbody .voucherdetails ._field {
  font-size: 11px;
  font-weight: 800;
  margin: 5px 0;
  text-align: left;
}
.printvoucher .voucherbody .details {
  margin: 5px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.printvoucher .voucherbody .details .Title {
  font-weight: 800;
  border-bottom: 2px solid black;
  width: 80%;
  margin: 0 auto;
  font-size: 11px;
}
.printvoucher .voucherbody .details ._user {
  font-size: 10px;
  font-weight: 800;
}
.printvoucher .voucherbody .details ._Date {
  font-size: 10px;
}
.printvoucher .voucherbody .voucheritems_print {
  display: grid;
  margin: 0 auto;
  width: 100%;
  font-size: 11px;
}
.printvoucher .voucherbody .voucheritems_print tbody {
  font-size: 11px;
}
.printvoucher .voucherbody .voucheritems_print tbody tr {
  border-bottom: 2px solid black;
}
.printvoucher .voucherbody .printtablevoucheritems {
  width: 95%;
  margin: 0 auto;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.footer img {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.Voucher_Dashboard {
  max-width: 90vw;
  display: grid;
  margin: 0 auto;
}

.sectorwrapper {
  display: grid;
  grid-gap: 5px;
}
.sectorwrapper.dark {
  background-color: #333333;
  color: #fff;
}
.sectorwrapper.dark .sector {
  background-color: #1b1b1b;
  color: #fff;
}
.sectorwrapper.dark .sector.Names {
  border: 1px rgb(0, 223, 0) solid;
}
.sectorwrapper.dark .sector.Stock_rate {
  border: 1px rgb(204, 0, 0) solid;
}
.sectorwrapper.dark.Names .addtocart .addtocartBtn {
  color: #fff;
}
.sectorwrapper .sector {
  border-radius: 15px;
  border: 0.5px rgb(0, 0, 0) solid;
  padding: 15px;
}
.sectorwrapper .sector:hover {
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid rgba(10, 104, 135, 0.1529411765);
  transition: 0.25s ease-out;
}
.sectorwrapper .sector.Names {
  border: 0.5px green solid;
}
.sectorwrapper .sector.Stock_rate {
  border: 0.5px rgb(128, 0, 0) solid;
}

@media screen and (max-width: 768px) {
  .sectorwrapper {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
}
@media screen and (max-width: 290px) {
  .sectorwrapper {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
}
.brand_Name {
  margin-bottom: 10px;
}

.productbrandselectlist {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.productbrandselect {
  cursor: pointer;
  margin-bottom: 5px;
}

/* Modal styles */
.modal_brand {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_brand-content {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 80vw;
  height: 50vh;
  max-height: 100%;
  overflow: auto;
  top: 0;
}
.modal_brand-content .Product_history_data {
  width: 100%;
  margin: 0 auto;
}
.modal_brand-content .Product_history_data._table {
  margin: 0 auto;
}

.product_page_wrapper {
  display: grid;
  grid-gap: 10px;
}
.product_page_wrapper .product_info_page {
  margin: o 25px;
}
.product_page_wrapper .Product_stocklevels {
  margin: 0 25px;
}

@media screen and (min-width: 1000px) {
  .product_page_wrapper {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr;
    grid-template-areas: "product_info_page Product_stocklevels";
  }
  .product_page_wrapper .product_info_page {
    grid-area: product_info_page;
  }
  .product_page_wrapper .Product_stocklevels {
    display: grid;
    grid-area: Product_stocklevels;
  }
}
:root {
  --background-color: white;
}

[data-theme=dark] {
  --background-color: black;
}

.content {
  --background-color: white;
}
.content.dark {
  background-color: #333333;
}/*# sourceMappingURL=styles.css.map */