.Navigation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 340000;
}

.Navigation-modal-content {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 350000;
  display: grid;
}
.Navigation-modal-content.dark {
  background-color: #333333;
  color: #fff;
}
.Navigation-modal-content ul {
  text-decoration: none;
  list-style: none; /* Remove default list-style (bullet points) */
}
.Navigation-modal-content ul li {
  margin-right: 10px;
  text-decoration: none;
  height: 30px;
}
.Navigation-modal-content ul a {
  height: 100%;
  text-decoration: none;
  display: grid;
  place-content: center;
  color: #0059ac; /* Text color for the menu items */
  transition: 1s ease-in;
  border: none;
  border-bottom: 1px solid rgba(128, 128, 128, 0.336);
  border-radius: 5px;
  font-weight: lighter;
  font-size: 14px;
}
.Navigation-modal-content ul a:hover {
  /*  background-color: #5fccff;  Background color on hover */
  background-color: rgba(11, 84, 148, 0.1450980392);
  box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  z-index: 100;
  transition: 0.25s ease-out;
  scale: 1.01;
}/*# sourceMappingURL=Style.css.map */