/* SalesByCustomerModal.scss */
.sales-by-Sales_rep-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sales-by-Sales_rep-modal-content {
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 95vw;
  width: 90%;
  max-height: 80vh;
}

.sales-by-Sales_rep-modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #777;
}/*# sourceMappingURL=Style.css.map */